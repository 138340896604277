// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import UnisaveAccountsPage from "./pages/UnisaveAccountsPage";
import ClaimPage from "./pages/ClaimPage";      //Old Claim Page
import GameResultsPage from "./pages/GameResultsPage.jsx";
import UniwinPage from "./pages/UniwinPage";
import WinningPage from "./pages/WinningPage";  //No need
import MintUnisavePage from "./pages/MintUnisavePage";
import PrizePage from "./pages/PrizePage";    //Old Game Page
import RewardPage from "./pages/RewardPage";  //Old Game Page
import GamesPage from "./pages/GamesPage";
import GameResultPage from "./pages/GameResultPage";  //Detail for each Game.
import JackpotsPage from "./pages/JackpotsPage.jsx"
import SponsorPage from "./pages/SponsorPage.jsx"
import ScheduleGamePage from "./pages/ScheduleGamePage.jsx"
import MintPowerDrop10000XPage from "./pages/MintPowerDrop10000XPage.jsx";
import PortfolioPage from "./pages/PortfolioPage";
import TestOpensea from "./pages/TestOpensea";


const App = () => {

  const handleSave = (combination) => {
    console.log('Saved numbers:', combination);
  };
  const handleClose = () => {
    console.log('Close Close.');
  };
  return (
    //No Need: ClaimPage, WinningPage, RewardPage
    <div className="px-3 md:px-7 lg:px-10 h-min-screen">
    <Router>   
        <NavBar/>          
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/games" element={<GamesPage />} />
          <Route path="/games/Upcoming" element={<GamesPage />} />
          <Route path="/games/Schedules" element={<ScheduleGamePage />} />
          <Route path="/games/Results" element={<GameResultsPage />} />

          <Route path="/Rewards" element={<UniwinPage />} />
          <Route path="/ticket" element={<MintUnisavePage />} />
          {/* <Route path="/portfolio" element={<TestOpensea />} /> */}
          <Route path="/portfolio" element={<UnisaveAccountsPage />} />
          <Route path="/gameResults" element={<GameResultPage />} />
          <Route path="/currentJackpot" element={<JackpotsPage />} />   
          <Route path="/sponsor" element={<SponsorPage />} />                    
        </Routes>           
    </Router>
    </div>
  );
};

export default App;
