// src/apollo-client.js
import { ApolloClient, InMemoryCache } from '@apollo/client';

const BLAST_POWERDROP_10000X_SUBGRAPH_URL = 'https://api.studio.thegraph.com/query/88583/blastpowercoin/version/latest';

const client = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/88583/subgraphunisavearbitrumsepolia/version/latest',
  cache: new InMemoryCache(),
});

export default client;
