import React, { useState, useEffect } from "react";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { UNISAVE_PRICE, checkingContractConfig } from "../contract.js";
import { parseUnits, formatUnits } from "viem";

import CircleInputs from "../components/CircleInputs.jsx";

import { savingContractConfig, usdbContractConfig } from "../contract.js";
import TopMenu from "../components/TopMenu.jsx";
import { FiInfo } from 'react-icons/fi';
import { FaCheck } from "react-icons/fa6";
import { FaTwitter } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import BridgeButton from "../components/BridgeButton.jsx";

const MintUnisavePage = ({onMint}) => {
  const { address: connectedAddress, isConnected } = useAccount();
  const [selectedAmount, setSelectedAmount] = useState("100");
  const [isChecked, setIsChecked] = useState(false);
  const [savedNumbers, setSavedNumbers] = useState(Array(6).fill(null));
  // const [referNumbers, setReferNumbers] = useState(Array(6).fill(null));
  
  const [inviterNumbers, setInviterNumbers] = useState(Array(6).fill(null));

  const [isShowMintResult, setShowMintResult] = useState(false); // State for nextGameId
  const [mintResult, setMintResult] = useState();
  const [usdbBalance, setUsdbBalance] = useState();

  // return (      
  //   <div className="flex flex-col mt-3 h-min-screen  justify-center items-center mb-20 md:mb-0">     
  //     Minting Soon.
  //     <a
  //       href="https://x.com/metta_powercoin"
  //       target="_blank" rel="noopener noreferrer"
  //       className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
  //     >
  //       <div className="flex items-center">
  //         <FaTwitter className="mr-2 text-gray-500" />
  //         <span>Follow Us On Twitter To Get Latest Update</span>
  //       </div>
  //       <FiExternalLink />
  //     </a>
  //   </div>
  // );
  

  // Function to handle changes from CircleInputRow
  const handleNumbersChange = (newValues) => {    
    setSavedNumbers(newValues);
    setShowMintResult(false);
    console.log("newValues:", newValues);
  };

  const handleInviterChange = (newValues) => {
    // setValues(newValues);
    setInviterNumbers(newValues);
    setShowMintResult(false);
    console.log("newValues:", newValues);
  };

  // Function to handle changes from CircleInputRow
  const handleInvitorChange = (newValues) => {
    // setValues(newValues);
    setInviterNumbers(newValues);
    console.log("newValues:", newValues);
  };

  const handleSave = (numbers) => {
    setSavedNumbers(numbers);
    console.log("Saved numbers:", numbers);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const { writeContract: approveToken } = useWriteContract();

  const { data: usdbBalanceData, refetch: fetchUSDBBalance } = useReadContract({
    ...usdbContractConfig,
    functionName: "balanceOf",
    args: [connectedAddress],
  });

  useEffect(() => {        
    // ${Number(formatUnits(BigInt(currency.amount), 18)).toLocaleString()} {/* Format ERC20 amount */}
    console.log("usdbBalanceData:", usdbBalanceData);
    if (usdbBalanceData != undefined || usdbBalanceData == 0n) {
      const usdbBalanceFormated = formatUnits(BigInt(usdbBalanceData), 18); 
      setUsdbBalance(usdbBalanceFormated);
      console.log("usdbBalanceFormated:", usdbBalanceFormated);
      if(usdbBalanceFormated < selectedAmount) {
        console.log("Not enough USDB Balance");
      } else {
        console.log("Enough USDB Balance");
      }
    }
  }, [usdbBalanceData]);

  const { data: usdbCheckingAllowance, refetch: fetchUSDBCheckingAllowance } = useReadContract({
    ...usdbContractConfig,
    functionName: "allowance",
    args: [connectedAddress, savingContractConfig.address],
  });

  const { data: mintPaused, refetch: fetchMintPaused } = useReadContract({
    ...savingContractConfig,
    functionName: "paused",    
  });

  // const requiredAmount = parseUnits(UNISAVE_PRICE.toString(), 18);
  const requiredAmount = parseUnits(selectedAmount, 18);  

  let needsApproval =
    usdbCheckingAllowance !== undefined &&
    BigInt(usdbCheckingAllowance) < BigInt(requiredAmount);

  useEffect(() => {
    if (usdbCheckingAllowance) {
      needsApproval =
        usdbCheckingAllowance !== undefined &&
        BigInt(usdbCheckingAllowance) < BigInt(requiredAmount);
      console.log("needsApproval:", needsApproval);
    }
  }, [usdbCheckingAllowance, selectedAmount]);

  const {
    data: mintHash,
    isError: isMintError,
    isSuccess: isMintSuccess,
    isLoading: isMintLoading,
    writeContract: mintUnisave,
  } = useWriteContract();

  const handleMint = () => {    
    const isInviterNumbersValid = inviterNumbers.every((value) => value !== null);
    const isSavedNumbersValid = savedNumbers.every((value) => value !== null);
    if (!isSavedNumbersValid) {
      return;
    }

    if (needsApproval) {
      approveToken({
        ...usdbContractConfig,
        functionName: "approve",
        args: [savingContractConfig.address, requiredAmount],
      });
    }

    console.log("inviterNumbers: ", inviterNumbers);
    const firstFive = savedNumbers.slice(0, 5);
    const sortedFirstFive = firstFive.sort((a, b) => (a > b ? 1 : -1));
    const reorderedSavedNumbers = sortedFirstFive.concat(savedNumbers.slice(5));
    console.log("reorderedSavedNumbers:", reorderedSavedNumbers);

    if(isInviterNumbersValid) {
      mintUnisave({
        ...savingContractConfig,
        functionName: "mint",
        args: [
          reorderedSavedNumbers.slice(0, 6).map((n) => BigInt(n)),
          inviterNumbers.slice(0, 6).map((n) => BigInt(n)),
          isInviterNumbersValid,
        ],
      });

      //If mint succesfull, we need to call Opensea to update Metadata. 
      //How to update metadata on Blur? 
    } else {
      mintUnisave({
        ...savingContractConfig,
        functionName: "mint",
        args: [
          reorderedSavedNumbers.slice(0, 6).map((n) => BigInt(n)),          
          Array(6).fill(BigInt(0)),
          isInviterNumbersValid,
        ],
      });
    }
  }; 

  useEffect(() => {
    if (isMintSuccess) {
      setMintResult("Mint Success.");
      setShowMintResult(true);
    }

    if (isMintSuccess && onMint) {
      onMint({ success: true, message: "Withdrawal successful" });      
    }
  }, [isMintSuccess, onMint]);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }  

  const [numbers, setNumbers] = useState(Array(6).fill(""));

  const handleInputChange = (index, value) => {
    // Ensure input is numeric and within the valid range
    let number = parseInt(value, 10);
    
    if (index < 5) {
      // White circles: only allow numbers from 1 to 69
      if (isNaN(number) || number < 1 || number > 69 || numbers.includes(number)) {
        return; // Ignore invalid input or duplicates
      }
    } else {
      // Red circle: only allow numbers from 1 to 26
      if (isNaN(number) || number < 1 || number > 26) {
        return; // Ignore invalid input
      }
    }

    const updatedNumbers = [...numbers];
    updatedNumbers[index] = number;
    setNumbers(updatedNumbers);
  };



  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (                          
    <div className="flex flex-col items-center gap-3 md:gap-5 pt-16 h-min-screen mb-36 md:mb-0">   
      <h2 className="text-xl lg:text-3xl font-bold text-OldBlue">Save To Earn Free Lotto Ticket NFT</h2>                  
      <div className="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 items-center rounded-lg gap-3 text-lg">
        <div>
          <h4 className="text-lg md:text-xl text-center font-bold">Input Ticket Numbers Below</h4>
          <div className="aspect-square relative w-full text-lg gap-1 flex rounded">                                      
            <svg width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="#003b79" />
              <polygon transform="translate(130, 200) scale(0.28)" points="100,20 130,75 200,75 145,120 160,180 100,150 40,180 55,120 0,75 70,75" fill="#fff" />
              <polygon transform="translate(790, 200) scale(0.28)" points="100,20 130,75 200,75 145,120 160,180 100,150 40,180 55,120 0,75 70,75" fill="#fff" />
              <polygon points="50,100 100,50 900,50 950,100 950,900 900,950 100,950 50,900" fill="none" stroke="white" stroke-width="10"/>
              <text x="500" y="250" font-size="60" font-weight="bold" textAnchor="middle" fill="#FFF">SaveTogether</text>
              <line x1="90" y1="400" x2="910" y2="400" stroke="#FFF" stroke-width="7"/><line x1="90" y1="600" x2="910" y2="600" stroke="#FFF"  stroke-width="7"/>
              {/* <rect x="90" y="410" width="820" height="180" rx="20" stroke="#fff" stroke-width="7" fill="#cd0039"/> */}
              {/* <text x="500" y="530" font-size="81" font-weight="bold" textAnchor="middle" fill="WHITE">Free To Play Lottery</text> */}

              <text x="500" y="750" font-size="60" font-weight="bold" textAnchor="middle" dy=".35em" fill="#fff">100 USDB</text>
              {/* <text x="90" y="900" font-size="35" textAnchor="start" fill="white">ID: TBD</text><text x="910" y="900" font-size="35" textAnchor="end" fill="white">Supply: 100</text> */}
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
              <CircleInputs 
                bgWhiteNumber="bg-white"
                values={savedNumbers} // Pass values to CircleInputRow
                onValueChange={handleNumbersChange} // Pass function to handle value changes            
              />               
            </div>                        
          </div>  
        </div>            
        
        <div>
          <h4 className="text-lg md:text-xl text-center font-bold">Save To Earn Free Lotto Ticket NFT</h4>     
          <div className="flex flex-col gap-3 justify-between h-[22rem] md:h-[26rem] md:aspect-square text-left text-lg">            
            <div className="flex flex-col gap-3 justify-between p-5 text-black border-2 text-left border-2 rounded-xl ">                            
              {/* <div className="flex flex-col text-left  justify-between gap-1 md:gap-3 ">
                <p>1. <span className="text-lg font-bold">Deposit to Play</span>: Minting a ticket requires depositing 100 USDB (on Blast) into a no-interest share pool. </p>
                <p>2. <span className="text-lg font-bold">No loss</span>: Ticket holders can redeem their deposit at any time for a full refund.</p>                
                <p>3. <span className="text-lg font-bold">Dev Unruggable</span>: The NFT contract ensures that developers cannot rug-pull, providing complete security.</p>
                <p>4. <span className="text-lg font-bold">Automatic Game Participation</span>: Your ticket automatically enters eligible games—no action needed from your side.</p>                                    
              </div>                                                                            */}

            
              <div className="text-base md:text-lg flex flex-row justify-between border-b">
                <div className="">MINT STATUS</div>
                <div className="font-bold">{mintPaused? "PAUSED": "ACTIVE"}</div>
              </div>

              <div className=" text-base md:text-lg flex flex-row justify-between border-b">
                <div className="">SAVE</div>
                <div className="font-bold">100 USDB</div>
              </div>

              <div className="text-base md:text-lg flex flex-row justify-between">
                <div className="">LOCK PERIOD</div>
                <div className="font-bold">NO. WITHDRAW ANYTIME</div>
              </div>
              <div className="text-center text-sm text-gray-500">
                By minting 1 Ticket NFT you will deposit 100 USDB(on Blast) into the NFT contract. 
                You can redeem Ticket NFT to get back 100 USDB anytime.                 
                Your Ticket NFT will auto participate in qualified lottery games. 
                <a href="https://savetogether.gitbook.io/savetogether" 
                target="_blank" rel="noopener noreferrer" className="text-OldBlue"> More detail here.</a>
              </div>                                                 
            </div>   
            <div className="text-center  border-2 rounded-lg">
            <p className="">Input Reference Ticket For More Reward</p>  
              <CircleInputs 
                bgWhiteNumber="bg-gray-100"
                values={inviterNumbers} // Pass values to CircleInputRow
                onValueChange={handleInviterChange} // Pass function to handle value changes            
              />               
            </div> 
            <div className="w-full h-full flex items-center justify-center"> 
              {isConnected? (      
                (usdbBalance < selectedAmount)? (                         
                  <BridgeButton                    
                    className={"text-gray-500 bg-gray-300 h-full p-2 flex justify-center items-center  text-center text-base rounded-lg w-full"}
                    onClick={handleMint}                    
                  >
                    Insufficent USDB. Select Bridge Menu for Options. 
                  </BridgeButton>
                ) : (
                  <button                    
                    className={"text-white bg-OldRed h-full p-2 flex justify-center items-center disabled:bg-red-900  text-center rounded-lg w-full hover:bg-red-500"}
                    onClick={handleMint}
                    disabled={isMintLoading || mintPaused || !savedNumbers.every((value) => value !== null)}
                  >
                    {mintPaused? "MINT PAUSED" : "MINT"}
                  </button>
                )              
              ) : (
                <ConnectButton label="Login"></ConnectButton>
              )}               
            </div>           
              
            </div> 
            
          </div>

          {isMintSuccess && <p className="text-green-600 text-center">Mint successful!</p>}
          {isMintError && <p className="text-red-600 text-center">Error minting. Please try again.</p>}   

      </div>
    </div>                               
  );
}

export default MintUnisavePage;
