import React, { useState, useEffect } from 'react';
import { useReadContract } from 'wagmi';
import { ethers } from 'ethers';
import { unigameContractConfig, tokenSymbols } from "../contract.js";
import { formatUnits } from 'viem';
import CountdownTimer from "../components/CountdownTimer.jsx";
import { useNavigate } from "react-router-dom";

import PrizeDisplay from "../components/PrizeDisplay.jsx";
import NumberDisplay from "../components/NumberDisplay.jsx";
import GameMenu from '../components/GameMenu.jsx';

// Mapping PrizeTier enum values to human-readable strings
const PRIZE_TIER_LABELS = [
    "Match 5 + Unipick",
    'Match 5',
    'Match 4 + Unipick',
    'Match 4',
    'Match 3 + Unipick',
    'Match 3',
    'Match 2 + Unipick',
    'Match 2',
    'Match 1 + Unipick',
    'Match Unipick',
    'Match 1'
  ];

  const UNIPOT_REWARD = [
    "YES",
    'NO',
    'NO',
    'NO',
    'NO',
    'NO',
    'NO',
    'NO',
    'NO',
    'NO',
    'NO'
  ];  

  const RewardPage = () => {
    const navigate = useNavigate();
    // const [gameId, setGameId] = useState(1); // Example gameId, you can update this as needed
    const [nextGameId, setNextGameId] = useState(1); // State for nextGameId
    const [nextGamePrize, setNextGamePrize] = useState(null);
    const [nextGame, setNextGame] = useState(null); // State to store game data
    const [currentGame, setCurrentGame] = useState(null); // State to store game data
    const [currentPrize, setCurrentGamePrize] = useState(null);

    const getTokenSymbol = (tokenAddress) => tokenSymbols[tokenAddress] || 'UNKNOWN';

    // Fetch nextGameId
    const { data: nextGameIdData, isLoading: isLoadingNextGameId } = useReadContract({
        ...unigameContractConfig,
        functionName: 'nextGameId',
    });
    
    // Fetch game info for selected game
    const { data: nextGameData, isLoading: isLoadingGameInfo, error: gameInfoError } = useReadContract({
      ...unigameContractConfig,
      functionName: 'getGame',
      args: [nextGameId],
    });

    // Read contract data using wagmi's useContractRead
    const { data: nextGamePrizeData, isLoading: isLoadingPrizeTiers, error: prizeTiersError} = useReadContract({
      ...unigameContractConfig,
      functionName: 'getPrizeTiers',
      args: [nextGameId-1],
    });

    // Read contract data using wagmi's useContractRead
    const { data: currentGamePrizeData, isLoading: isLoadingCurrentGamePrize, error: currentGamePrizeError} = useReadContract({
      ...unigameContractConfig,
      functionName: 'getPrizeTiers',
      args: [nextGameId-1],
    });

    // Fetch game info for selected game
    const { data: currentGameData, isLoading: isLoadingCurrentGameData, error: currentGameDataError } = useReadContract({
      ...unigameContractConfig,
      functionName: 'getGame',
      args: [nextGameId-1],
    });

    useEffect(() => {
        if (nextGameIdData) {
          setNextGameId(Number(nextGameIdData)); // Set nextGameId when data is fetched          
        }
      }, [nextGameIdData]);
  
    useEffect(() => {
      if (nextGamePrizeData) {
        const [tiers, prizes] = nextGamePrizeData;
        // Prepare prize data in the format needed for the table
        const formattedPrizeData = tiers.map((tier, index) => {
          const prize = prizes[index];
          return {
            tier,
            currencies: prize.currencies
          };
        });
        setNextGamePrize(formattedPrizeData);        
      }
    }, [nextGamePrizeData]);

    useEffect(() => {
      if (nextGameData) {
        const [id, wonCommonNumbers, wonUniNumber, gameTime, claimedTokenIds] = nextGameData;
        setNextGame({ id, wonCommonNumbers, wonUniNumber, gameTime, claimedTokenIds });
      }
    }, [nextGameData]);

    useEffect(() => {
      if (currentGamePrizeData) {
        const [tiers, prizes] = currentGamePrizeData;
        // Prepare prize data in the format needed for the table
        const formattedPrizeData = tiers.map((tier, index) => {
          const prize = prizes[index];
          return {
            tier,
            currencies: prize.currencies
          };
        });
        setCurrentGamePrize(formattedPrizeData);
      }
    }, [currentGamePrizeData]);

    useEffect(() => {
      if (currentGameData) {
        const [id, wonCommonNumbers, wonUniNumber, gameTime, claimedTokenIds] = currentGameData;
        setCurrentGame({ id, wonCommonNumbers, wonUniNumber, gameTime, claimedTokenIds });
      }
    }, [currentGameData]);
  
    if (isLoadingPrizeTiers || isLoadingGameInfo || isLoadingNextGameId) return <div>Loading...</div>;
    if (prizeTiersError) return <div>Error: {prizeTiersError.message}</div>;
    if (gameInfoError) return <div>Error: {gameInfoError.message}</div>;

    console.log("nextGamePrize: ",nextGamePrize);
    
    const today = new Date();
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    console.log(nextWeek);

    const handleViewResult = () => {
      navigate("/unigame");
    };

    const handleSaveToWin = () => {
      navigate("/newSaving");
    };

    function getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
  
      return `${year}-${month}-${day}`;
    }

    return (
      <div className="">   
        <GameMenu _activeTab="results"></GameMenu>
        <div className="flex flex-row gap-5">            
          {currentGame && (                  
              <div className="flex flex-1 flex-col justify-between h-50 bg-gradient-to-t from-gray-100 to-gray-200 rounded-lg overflow-hidden col-span-2">
                <div className="p-4 text-center flex-1">
                  <h2 className="text-3xl font-bold text-center">
                    Winning Numbers
                  </h2>                     
                  <div className="mt-4 flex flex-col items-center">                      
                    <p className="text-lg font-bold text-center">{getCurrentDate().toLocaleString()}</p>                                  
                    <NumberDisplay numbers={[1,2,3,4,5,6]} />
                  </div>                                                                   
                  
                  <div className="mt-4">
                      <h3 className="text-lg">Estimated Jackpot</h3>  
                      <p className="text-lg font-bold text-[#FF0000]">$1,000,000</p>                
                  </div>
                </div>
                                
              </div>
            
            )}
          <div className="flex flex-1 flex-col justify-between h-50 bg-gradient-to-t from-gray-100 to-gray-200 rounded-lg overflow-hidden col-span-2">
            <div className="p-4 text-center text-lg flex-1">
              <h2 className="text-3xl font-bold text-center">
                Winners
              </h2>
              <p className="text-lg font-bold text-center">{getCurrentDate().toLocaleString()}</p>
              <p className="mt-4">Unisave Jackpot Winner</p>
              <p className="font-bold">None</p>
              <p className="mt-3">Match 5 Winner</p>
              <p className="font-bold">None</p>
              <p className="mt-3">Match 4 + Redball Winner</p>                
              <p className="font-bold text-[#FF0000]">3</p>
            </div>                       
          </div>          
        </div>
          
      {nextGame && (
        <h1 className="text-2xl text-center font-bold mb-4 mt-4">Game {nextGame.id.toLocaleString()} Prizes</h1>
      )} 
                
        {/* Prize Tiers Section */}
        {nextGamePrize ? (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">PRIZE TIER</th>
                    {/* Dynamically create headers based on the number of currencies in the first prize tier */}
                    {nextGamePrize[0].currencies.map((currency, index) => (
                      <th key={index} className="py-3 px-6 text-left">{getTokenSymbol(currency.tokenAddress)}</th>
                    ))}
                    <th className="py-3 px-6 text-left">UNIPOT NFT</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm">
                  {nextGamePrize.map((prize, prizeIndex) => (
                    <tr key={prizeIndex} className="border-b border-gray-300 hover:bg-gray-100">
                      {/* Prize Tier Column */}
                      <td className="py-3 px-6 text-left font-medium">
                        {PRIZE_TIER_LABELS[prize.tier]}                        
                      </td>
        
                      {/* Currency Amount Columns */}
                      {prize.currencies.map((currency, currencyIndex) => (
                        <td key={currencyIndex} className="py-3 px-6 text-left">
                          ${Number(formatUnits(BigInt(currency.amount), 18)).toLocaleString()} {/* Format ERC20 amount */}
                        </td>
                      ))}                      
        
                      {/* Handle missing currencies by filling empty cells */}
                      {nextGamePrize[0].currencies.length > prize.currencies.length &&
                        Array(nextGamePrize[0].currencies.length - prize.currencies.length).fill(0).map((_, idx) => (
                          <td key={`empty-${prizeIndex}-${idx}`} className="py-3 px-6">N/A</td>
                        ))}

                      <td className="py-3 px-6 text-left font-medium">
                        {UNIPOT_REWARD[prize.tier]}
                      </td>
                        
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        ) : (
          <div>No Prize Data Found</div>
        )}


      </div>
    );
  };
  
  export default RewardPage;