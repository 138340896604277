import React, { useState, useEffect } from 'react';

// Custom hook for debouncing
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const CircleInput = ({ value, onChange, index, message, bgWhiteNumber }) => {
  // Apply different styles for the sixth input
  const circleClasses = index === 5 
    ? 'bg-red-500 text-white' // Sixth circle with red background and white text
    : `${bgWhiteNumber} text-black`; // Other circles

  return (
    <div className={`flex flex-col items-center justify-center mx-1 `}>
      <div className={`flex items-center text-xl justify-center h-10 w-10 md:h-12 md:w-12 rounded-full ${circleClasses} shadow-md`}>
        <input 
          type="text"
          value={value === null ? '' : value} // Display empty string if value is null
          onChange={onChange}
          placeholder={index+1}
          maxLength="2"
          className="w-full h-full text-center bg-transparent outline-none font-bold text-xl"
        />
      </div>
      {/* Display validation message */}
      {message && (
        <p className="text-xs text-red-500 mt-1">
          {message}
        </p>
      )}
    </div>
  );
};

const CircleInputRow = ({ values, onValueChange, bg, bgWhiteNumber }) => {
  const [messages, setMessages] = useState(Array(6).fill(''));
  const debouncedValues = useDebounce(values, 500); // Delay of 300ms

  useEffect(() => {
    validateValues(debouncedValues);
  }, [debouncedValues]);

  // Validate values after debounced input
  const validateValues = (debouncedValues) => {
    const newMessages = Array(6).fill('');
    for (let i = 0; i < debouncedValues.length; i++) {
      const number = debouncedValues[i];
      if (number !== null) {
        if (i < 5 && (number < 1 || number > 69 || values.slice(0, 5).filter(n => n === number).length > 1)) {
          newMessages[i] = number < 1 || number > 69 ? 'Out of range (1-69)' : 'Duplicate number';
        } else if (i === 5 && (number < 1 || number > 26)) {
          newMessages[i] = 'Out of range (1-26)';
        }
      }
    }
    setMessages(newMessages);
  };

  // Handle change for each input
  const handleChange = (index, e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) { // Allow only numeric input
      const number = newValue === '' ? null : parseInt(newValue, 10); // Convert to number or set to null
      updateValue(index, number);
    }
  };

  // Update the value for a specific index
  const updateValue = (index, value) => {
    const newValues = [...values];
    newValues[index] = value;
    onValueChange(newValues);
  };

  return (
    <div className={`flex justify-center items-center p-2 rounded-lg ${bg} `}>
      {values.map((value, index) => (
        <CircleInput 
          key={index}
          value={value}
          onChange={(e) => handleChange(index, e)}          
          index={index}
          message={messages[index]} // Pass message to display
          bgWhiteNumber={bgWhiteNumber}
        />
      ))}
    </div>
  );
};

export default CircleInputRow;
