import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";



const TopMenu = () => {
        
    return (      
        <div className='w-full flex flex-row justify-between pb-3'>
            <p className='flex items-center'><span className="text-red-600 font-bold text-xl ">POWER</span><span className=" text-blue-900 align-bottom font-bold text-xl">COIN</span></p>          
            <ConnectButton chainStatus="icon" showBalance={false}   
                accountStatus={{
                    smallScreen: 'avatar',
                    largeScreen: 'full',
                 }}>
            </ConnectButton>
        </div>      
    )

};
export default TopMenu;