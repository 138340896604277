import React from 'react';

// Component to display each number with colored border
const NumberCircle = ({ number, isMatch, index }) => {
  const circleClasses = isMatch 
    ? 'border-2 border-green-500' // Green border for match
    : 'border-2 border-fuchsia-500'; // Red border for mismatch

  const circleRedWhite = index === 5 
    ? 'bg-red-500 text-white' // Sixth circle with red background and white text
    : 'bg-gradient-to-b from-gray-100 to-gray-300 text-black'; // Other circles

  return (
    <div className={`flex items-center justify-center h-10 w-10 rounded-full ${circleClasses} ${circleRedWhite} m-1 shadow-md relative`}>
      <span className="font-bold text-xl">{number}</span>
      {isMatch && (
        <div className="absolute -bottom-3 -left-3 h-6 w-6 bg-green-500 text-white rounded-full flex items-center justify-center text-xs">
          ✓
        </div>
      )}
    </div>
  );
};

// Component to display the array comparison
const NumberComparison = ({ resultNumbers, ticketNumbers }) => {
  const checkMatchNumber = (resultNumber, index) => {      
    const resultNum = Number(resultNumber); // Convert BigInt to regular number
    const ticketNum = Number(ticketNumbers[index]);

    if (index === 5) {
      return resultNum === ticketNum; // Compare sixth number directly
    } else {
      return ticketNumbers.slice(0, 5).map(Number).includes(resultNum); // Check first five numbers
    }    
  };

  return (
    <div className="flex justify-center items-center">
      {resultNumbers?.map((num, index) => (
        <NumberCircle 
          key={index}
          number={num}
          index={index}  
          isMatch={checkMatchNumber(num, index)}          
        />
      ))}
    </div>
  );
};

export default NumberComparison;
