import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAccount, useReadContract, useReadContracts, useWriteContract } from "wagmi";
import {
  unigameContractConfig,
  savingContractConfig,
  tokenSymbols,
  prizeManagerContractConfig,
} from "../contract.js";
import { formatUnits } from 'viem';
import CircleInputs from "../components/CircleInputs.jsx";
import NumberComparison from "../components/NumberComparison.jsx";
import TopMenu from "../components/TopMenu.jsx";

// Mapping PrizeTier enum values to human-readable strings
const PRIZE_TIER_LABELS = [
  'Match 5 + Megaball',
  'Match 5',
  'Match 4 + Megaball',
  'Match 4',
  'Match 3 + Megaball',
  'Match 3',
  'Match 2 + Megaball',
  // 'Match 2',
  'Match 1 + Megaball',
  'Match Megaball'
  // 'Match 1'
];

const UniwinPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenId = queryParams.get("unisaveTokenId");   
  let unisaveNumbers = queryParams.get("unisaveNumbers");
  // const tokenNumbers = unisaveNumbers
  const tokenNumbers = unisaveNumbers !== null? unisaveNumbers.split(/[,|]/).map(part => parseInt(part, 10)): Array(6).fill(null);   
  
  const [savedNumbers, setSavedNumbers] = useState(tokenNumbers);


  // const [unisaveNumbers, setUnisaveNumbers] = useState(Array(6).fill(null));

  const [nextGameIds, setNextGameIds] = useState(); // State for nextGameId
  const [prizeResults, setPrizeResults] = useState([]);
  const [gameData, setGameData] = useState(null); // State to store game data  
  // const [unisaveData, setUnisaveData] = useState([]);
  const getTokenSymbol = (tokenAddress) => tokenSymbols[tokenAddress] || 'UNKNOWN';
  

  // Function to handle changes from CircleInputRow
  const handleUnisaveNumbersChange = (newValues) => {
    // setValues(newValues);
    setSavedNumbers(newValues);
    console.log("newValues:", newValues);    
  };
  
  const handleClaimPrize = (unisaveTokenId, gameId) => {
    console.log("handleClaimPrize unisaveTokenId:", unisaveTokenId);
    console.log("handleClaimPrize gameId:", gameId);
    claimPrize({
      ...prizeManagerContractConfig,
      functionName: "claimPrize",
      args: [
        BigInt(gameId),
        BigInt(unisaveTokenId),
      ],
    });
  };

  const handleViewGame = () => {
    console.log("view game");
  };

  const { writeContract: claimPrize } = useWriteContract();

  // Fetch nextGameId
  const { data: nextGameIdData, isLoading: isLoadingNextGameId } = useReadContract({
    ...unigameContractConfig,
    functionName: 'nextGameId',
  });

  // Once nextGameId is fetched, generate gameIds and fetch game data
  useEffect(() => {
    if (nextGameIdData) {      
      const totalGames = Number(nextGameIdData);  // Convert to number      
      const gameIds = Array.from({ length: totalGames}, (_, i) => i + 1);  // Generate [1, 2, ..., nextGameId-1]      
      setNextGameIds(gameIds);  // Store the game IDs in state       
      console.log("gameIds",gameIds);        
    }
  }, [nextGameIdData]); 
  
  // Fetch games and prizes data using generated gameIds
  const { data: gamesAndPrizes, isError, isLoading, refetch:refetchGamesAndPrizes, error  } = useReadContract({
    ...unigameContractConfig,
    functionName: 'getGamesAndPrizes',
    args: [nextGameIds],  // Use the gameIds fetched from nextGameId
  });  

  // Check nextGameIds is defined and has valid game IDs
  // useEffect(() => {
  //   if (nextGameIds && nextGameIds.length > 0) {
  //     refetchGamesAndPrizes(); // Refetch when nextGameIds is ready
  //   }
  // }, [nextGameIds, refetchGamesAndPrizes]); 

  const { data: unisaveTokenId, refetch:refetchTokenId } = useReadContract({
    ...savingContractConfig,
    functionName: 'isUnisaveExisted',
    args: [savedNumbers.slice(0, 6)],  
  });

  useEffect(() => {
    if (savedNumbers) {
      refetchTokenId();
    }    
  }, [savedNumbers]);

  useEffect(() => {
    console.log("unisaveTokenId:",unisaveTokenId);
    if (unisaveTokenId) {    
      refetchUnisaveData();
      
    } else {

    }   
  }, [unisaveTokenId]);  
  
  const { data: unisaveData , refetch:refetchUnisaveData } = useReadContract({
    ...savingContractConfig,
    functionName: "getUnisave",
    args: [unisaveTokenId],
  });
    

  useEffect(() => {
    console.log("unisaveData: ",unisaveData);
    console.log("gamesAndPrizes: ",gamesAndPrizes);
    if (gamesAndPrizes && unisaveData) {
      const results = gamesAndPrizes[0].map((gameInfo, index) => {              
        const gameTime = new Date(Number(gameInfo.gameTime) * 1000).toLocaleString();        
        const resultNumbers = gameInfo.resultNumbers.map(Number);             
        const unisaveNumbers = unisaveData[0].map(Number);                 
       
        const highestPrizeTier = determineHighestPrizeTier(
          resultNumbers,
          unisaveNumbers          
        );

        // Get the prize rewards for the highest prize tier        
        const prizes = gamesAndPrizes[1][index][highestPrizeTier]?.currencies;        
        console.log("unisaveNumbers", unisaveNumbers);
        console.log("resultNumbers", resultNumbers);

        return {
          gameId: gameInfo.id,
          resultNumbers,
          unisaveNumbers,          
          gameTime,
          highestPrizeTier,
          prizes,
          isClaimEnable: !gameInfo.claimedTokenIds?.includes(unisaveTokenId) && (prizes?.length > 0 || highestPrizeTier == 0),
        };
      });
      
      setPrizeResults(results);
    }
  }, [gamesAndPrizes, unisaveData]);
  

  function determineHighestPrizeTier(
    resultNumbers,
    unisaveNumbers   
  ) {
    const isMatchRedNumber = resultNumbers[5] === unisaveNumbers[5];
    const resultWhiteNumbers = resultNumbers.slice(0,5);
    const unisaveWhiteNumbers = unisaveNumbers.slice(0,5);
    const matchCount = countMatchingWhiteNumbers(
      resultWhiteNumbers,
      unisaveWhiteNumbers
    );

    if (matchCount === 5 && isMatchRedNumber) {
      return 0;
    } else if (matchCount === 5 && !isMatchRedNumber) {
      return 1;
    } else if (matchCount === 4 && isMatchRedNumber) {
      return 2;
    } else if (matchCount === 4 && !isMatchRedNumber) {
      return 3;
    } else if (matchCount === 3 && isMatchRedNumber) {
      return 4;
    } else if (matchCount === 3 && !isMatchRedNumber) {
      return 5;
    } else if (matchCount === 2 && isMatchRedNumber) {
      return 6;
    // } else if (matchCount === 2 && !isMatchRedNumber) {
      // return 7;
    } else if (matchCount === 1 && isMatchRedNumber) {
      return 7;
    // } else if (matchCount === 1 && !isMatchRedNumber) {
    //   return 9;
    } else if (matchCount === 0 && isMatchRedNumber) {
      return 8;
    } 
    // else {
    //   return 9; // Instead of revert, return "No Prize" in the frontend
    // }
  }

  function countMatchingWhiteNumbers(resultWhiteNumbers, unisaveWhiteNumbers) {
    return unisaveWhiteNumbers.reduce(
      (count, num) => count + (resultWhiteNumbers.includes(num) ? 1 : 0),
      0
    );
  }  
  

  return (    
    <div className="flex flex-col w-full mb-20">
      <TopMenu />                    
      <div className="flex flex-col text-base sm:text-lg shadow-md p-2 rounded-lg gap-4 sm:gap-5 mb-4">                
        <div className="text-center space-y-2 font-bold text-xl"> 
          <p>Check Reward For Numbers</p>           
          <CircleInputs
            values={savedNumbers}
            onValueChange={handleUnisaveNumbersChange}            
          />          
        </div>
      </div>                           
      
      <div className="flex flex-col text-base sm:text-lg gap-4 sm:gap-5 items-center">  
        {prizeResults.length > 0 ? (      
          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-5">
            {prizeResults.map((result, index) => (
              <li key={index} className="bg-gradient-to-t from-gray-100 to-gray-300 text-black flex flex-col gap-y-3 text-center border p-4 rounded-lg shadow"> 
                <h2 className="text-xl sm:text-2xl md:text-3xl text-center">Game {result.gameId.toString()}</h2>           
                <p>{result.gameTime}</p>  
                <NumberComparison array1={result.resultNumbers} array2={result.unisaveNumbers} />  
                {result.highestPrizeTier === 0 ?
                  ( <p>Jackpot Grand Prize.</p>) : 
                  (
                    result.prizes?.length > 0 ? (
                      result.prizes.map((currency, currencyIndex) => (                                      
                        <div className="flex justify-center" key={currencyIndex}>
                          <p className="text-base sm:text-lg md:text-xl font-bold bg-green-500 w-fit text-white rounded p-2">{Number(formatUnits(BigInt(currency.amount), 18)).toLocaleString()} {getTokenSymbol(currency.tokenAddress)}</p>                                        
                        </div>                             
                      ))                
                    ) : (
                      <p>No rewards available.</p>
                    )
                  )
                }          
                 
                
                <div className="flex flex-col sm:flex-row mt-4 space-y-2 sm:space-y-0 sm:space-x-2">
                  <button              
                    className={`bg-white hover:bg-gray-100 p-2 text-center rounded-lg w-full ${
                      result.isClaimEnable
                        ? 'text-black hover:text-zinc-500 cursor-pointer'
                        : 'text-zinc-300 cursor-not-allowed'
                    }`}
                    onClick={() => handleClaimPrize(unisaveTokenId, result.gameId)}
                    disabled={!result.isClaimEnable}
                  >
                    {result.isClaimEnable
                      ? 'Claim Prize'
                      : result.prizes?.length > 0 ? 'Prize Claimed' : 'No Prize'}
                  </button>
                  <button 
                    className="text-black text-base bg-white p-2 hover:bg-gray-100 text-center rounded-lg w-full"
                    onClick={handleViewGame()}
                  >
                    View Game
                  </button>
                </div>           
              </li>
            ))}
          </ul>
        ) : (               
          <p className="text-center">No prize results available.</p>          
        )}      
      </div>      
    </div>
  );     
};

export default UniwinPage;
