import React, { useState, useEffect   } from "react";
import { useAccount, useReadContract, useSimulateContract, usePublicClient , useWriteContract, useWaitForTransactionReceipt } from "wagmi";

import { UNISAVE_PRICE } from "../contract.js";
import MintUnisavePage from "./MintUnisavePage.jsx";
import ListUnisaveSavingsPage from "./ListUnisaveSavingsPage.jsx";
import { savingContractConfig, unigameContractConfig, usdbContractConfig } from "../contract.js";
import { useNavigate } from "react-router-dom";

import { GET_USER_CHECKING_ACC_DATA, GET_WHITELIST_UPDATES, GET_WHITELIST_TOGGLES } from '../queries.js';
import { formatUnits, parseUnits } from "viem";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdNotifications } from "react-icons/md";
import TopMenu from "../components/TopMenu.jsx"
import PrizeDisplay from "../components/PrizeDisplay.jsx";
import NumberComparison from "../components/NumberComparison.jsx";
import { useQuery, gql } from '@apollo/client';
import { FaTwitter } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';

const GET_NFTS_BY_OWNER = gql`
  query getOwnedTokens($owner: String!) {
    unisaveSavingAccounts_collection(where: { owner: $owner }) {
      id
      numbers
      inviterTokenId
      inviteeTokenIds
    }
  }
`;

const UnisaveAccountsPage = () => {
  const navigate = useNavigate();

  const { address, isConnected } = useAccount();
  const [activeSavingTab, setActiveSavingTab] = useState("listSavingAccounts"); // State to manage active tab
  const [activeCheckingTab, setActiveCheckingTab] = useState("deposit"); // State to manage active tab
  const [activeAccountTab, setActiveAccountTab] = useState("saving"); // State to manage active tab
  const [isReloadSavingAccount, setIsReloadSavingAccount] = useState(false);

  const [withdrawValue, setWithdrawValue] = useState('');
  const [sendValue, setSendValue] = useState('');
  const [depositValue, setDepositValue] = useState('');
  const [depositResult, setDepositResult] = useState('');
  const [withdrawResult, setWithdrawResult] = useState('');
  const [sendResult, setSendResult] = useState('');

  const [depositHash, setDepositHash] = useState('');
  const [withdrawHash, setWithdrawHash] = useState('');
  const [gamesInfo, setGamesInfo] = useState(null);

  const [nfts, setNfts] = useState([]);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const { writeContract: approveToken } = useWriteContract();  
  const provider = usePublicClient ();  

  // return (      
  //   <div className="flex flex-col mt-3 h-min-screen  justify-center items-center mb-20 md:mb-0">     
  //     Comming Soon.
  //     <a
  //       href="https://x.com/metta_powercoin"
  //       target="_blank" rel="noopener noreferrer"
  //       className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
  //     >
  //       <div className="flex items-center">
  //         <FaTwitter className="mr-2 text-gray-500" />
  //         <span>Follow Us On Twitter To Get Latest Update</span>
  //       </div>
  //       <FiExternalLink />
  //     </a>
  //   </div>
  // );

  // const { loadingUserData, errorUserData, data: userData, refetch: refetchUserData} = useQuery(GET_NFTS_BY_OWNER, {    
  //   variables: { owner: address?.toLowerCase() },
  //   skip: !isConnected,
  // });
  

  const { data, isError: isDepositTxError, isSuccess: isDepositTxSuccess, 
    isLoading: isDepositTxLoading, writeContract: deposit } = useWriteContract();

  const { isError: isRedeemError, isSuccess: isRedeemSuccess, 
    isLoading: isRedeemLoading, writeContract: redeem } = useWriteContract();  
      
  const { writeContractAsync:depositAsync } = useWriteContract({

  });    
  const { writeContractAsync:withdrawAsync } = useWriteContract({

  });

  const handleDepositChecking = async () => {
    if (!depositValue) {
      setDepositResult('Please enter a deposit amount.');
      return;
    }

    const depositAmount = parseUnits(depositValue, 18); // Convert deposit value to the required units    
    console.log("depositAmount:", depositAmount);
    console.log("allowwwAmount:", usdbCheckingAllowance);     
    
    let needsApproval =
    usdbCheckingAllowance !== undefined &&
      BigInt(usdbCheckingAllowance) < BigInt(depositAmount);

    if (needsApproval) {
      approveToken({
        ...usdbContractConfig,
        functionName: "approve",
        args: [checkingContractConfig.address, depositAmount],
      });
    }

    try {
      // Call the depositAsync function
      const depositTx = await depositAsync({
        ...checkingContractConfig,
        functionName: 'deposit', // Contract function to call
        args: [depositAmount], // Function arguments
      });

      console.log('depositTx sent:', depositTx);
      setDepositHash(depositTx); // Save the transaction hash
      setDepositResult('Transaction is being processed...');      

    } catch (err) {
      console.error('Error executing transaction:', err);
      setDepositResult(`Transaction error: ${err.message}`);
    }    
  };

  // Wait for transaction receipt when DepositHash updates
  const { data: receiptData, isError: isReceiptDepositError, isLoading: isReceiptLoading, isSuccess: isReceiptDepositSuccess, error: receiptError } = useWaitForTransactionReceipt({
    hash: depositHash,    
  });
  
  useEffect(() => {    
    if (isReceiptDepositSuccess) {
      console.log('isReceiptDepositSuccess:', isReceiptDepositSuccess);
      console.log('Receipt Data:', receiptData);
      setDepositResult("Deposit Successed!");
      refetchUserData();      
      return;
    }

    if (isReceiptDepositError) {
      console.log('isReceiptDepositError:', isReceiptDepositError);
      console.log('Receipt Data:', receiptData);
      setDepositResult("Deposit Failed!");
      return;
    }    
  }, [isReceiptDepositSuccess], [isReceiptDepositError]);

  const handleWithdrawChecking = async () => {
    if (!withdrawValue) {
      setWithdrawResult('Please enter a withdraw amount.');
      return;
    }

    const withdrawAmout = parseUnits(withdrawValue, 18); // Convert deposit value to the required units

    try {
      // Call the depositAsync function
      const withdrawTx = await withdrawAsync({
        ...checkingContractConfig,
        functionName: 'withdraw', // Contract function to call
        args: [withdrawAmout], // Function arguments
      });

      console.log('withdrawTx sent:', withdrawTx);
      setWithdrawHash(withdrawTx); // Save the transaction hash
      setWithdrawResult('Transaction is being processed...');      

    } catch (err) {
      console.error('Error executing transaction:', err);
      setWithdrawResult(`Transaction error: ${err.message}`);
    }    
  };

  // Wait for transaction receipt when DepositHash updates
  const { data: withdrawReceiptData, isError: isWithdrawReceiptError, isLoading: isWithdrawReceiptLoading, isSuccess: isWithdrawReceiptSuccess, error: withdrawReceiptError } = useWaitForTransactionReceipt({
    hash: withdrawHash,    
  });
  
  useEffect(() => {    
    if (isWithdrawReceiptSuccess) {
      console.log('isWithdrawReceiptSuccess:', isWithdrawReceiptSuccess);
      console.log('Receipt Data:', receiptData);
      setWithdrawResult("Withdraw Successed!");
      refetchUserData();
      return;
    }

    if (isWithdrawReceiptError) {
      console.log('isReceiptDepositError:', isWithdrawReceiptError);
      console.log('Receipt Data:', withdrawReceiptData);
      setWithdrawResult("Withdraw Failed!");
      return;
    }    
  }, [isWithdrawReceiptSuccess], [isWithdrawReceiptError]); 
  
  const handleSendChecking = async () => {
    if (!sendValue) {
      setSendResult('Please enter a send amount.');
      return;
    }

    const sendAmout = parseUnits(sendValue, 18); // Convert deposit value to the required units
  }

  const {
    data: balanceOf,
    isLoading: isBalanceLoading,
    refetch: refetchBalance,
  } = useReadContract({
    ...savingContractConfig,
    functionName: "balanceOf",
    args: [address],
  });

  const nftBalance = balanceOf ? Number(balanceOf) : 0;
  const savingAccountsBalance = nftBalance * UNISAVE_PRICE;

  const { data: allGameInfoData, isLoading: isLoadingNextGameId } = useReadContract({
    ...unigameContractConfig,
    functionName: 'getAllGamesInfo',    
  });

  useEffect(() => {    
    if (allGameInfoData) {
      setGamesInfo(allGameInfoData);   
    }    
  }, [allGameInfoData]);

  const getPrize = ( ticketNumbers, resultNumbers, prizeInfo) => {        
    if(ticketNumbers == undefined || resultNumbers == undefined) {      
      return;
    }
    let commonNumbers = 0;
    let matchRedNumber = false;
    const ticketNum = ticketNumbers?.map(Number);
    const firstFiveTicketNumbers = ticketNumbers?.slice(0, 5).map(Number);
  
    // Compare first five numbers
    for (let i = 0; i < 5; i++) {
      if (resultNumbers?.includes(ticketNum[i])) {      
        commonNumbers += 1;
      }
    }
  
    // Compare the sixth number (Powerball)
    if (ticketNumbers[5] === resultNumbers[5]) {
      matchRedNumber = true;
    }

    // console.log("commonNumbers:", commonNumbers);
    // console.log("matchRedNumber:", matchRedNumber);
    // console.log("prizeInfo:", prizeInfo);
    

    if (commonNumbers === 0 && matchRedNumber) {
      return prizeInfo.matchRedNumberPrize;
    } else if (commonNumbers === 1 && matchRedNumber) {
      return prizeInfo.match1PlusRedNumberPrize;
    } else if (commonNumbers === 2 && matchRedNumber) {
      return prizeInfo.match2PlusRedNumberPrize;    
    } else if (commonNumbers === 3 && !matchRedNumber) {
      return prizeInfo.match3Prize;
    } else if (commonNumbers === 3 && matchRedNumber) {
      return prizeInfo.match3PlusRedNumberPrize;
    } else if (commonNumbers === 4 && !matchRedNumber) {
      return prizeInfo.match4Prize;
    } else if (commonNumbers === 4 && matchRedNumber) {
      return prizeInfo.match4PlusRedNumberPrize;
    } else if (commonNumbers === 5 && !matchRedNumber) {
      return prizeInfo.match5;
    } else if (commonNumbers === 5 && matchRedNumber) {
      return prizeInfo.match5PlusRedNumberPrize;
    } else {
      return 9;
    }
  };  
  

  // Handler to switch tabs
  const handleBlur = () => {
    window.open('https://www.blur.io', '_blank');
  };

  const handleRedeem = (tokenId) => {
    console.log(`Redeem clicked for tokenId ${tokenId}`);
    redeem({
      ...savingContractConfig,
      functionName: "redeem",
      args: [tokenId],
    });
  };

  // Handler to switch tabs
  const handleChangeNumbers = (tokenId) => {
    console.log(`Change Numbers for tokenId ${tokenId}`);
    alert("change numbers.")
    // redeem({
    //   ...savingContractConfig,
    //   functionName: "burnAndMintNewTicket",
    //   args: [tokenId],
    // });
  };
  

  const handleDeposit = () => {
    const depositAmount = parseUnits(depositValue, 18);
    console.log("depositAmount:", depositAmount);
    console.log("allowwwAmount:", usdbCheckingAllowance);
      
    
    let needsApproval =
    usdbCheckingAllowance !== undefined &&
      BigInt(usdbCheckingAllowance) < BigInt(depositAmount);

    if (needsApproval) {
      approveToken({
        ...usdbContractConfig,
        functionName: "approve",
        args: [checkingContractConfig.address, depositAmount],
      });
    }    

    deposit({
      ...checkingContractConfig,
      functionName:"deposit",
      args: [depositAmount]
    });
    refetchUserData();
  };

  const handleWithdraw = () => {
    const withdrawAmount = parseUnits(withdrawValue, 18);
    console.log("handleWithdraw:", withdrawAmount);
    withdraw({
      ...checkingContractConfig,
      functionName:"withdraw",
      args: [withdrawAmount]
    });
  }; 

  const safeParseUnits = (userData, decimals) => {
    // console.log("safeParseUnits:", userData);
    if (userData === undefined || userData === null || userData === "") {
      return "0"; // or handle error case
    }

    if (userData.user === undefined || userData.user === null || userData.user === "") {
      return "0"; // or handle error case
    }
    if (userData.user.balance === undefined || userData.user.balance === null || userData.user.balance === "") {
      return "0"; // or handle error case
    }

    try {            
      return formatUnits(userData.user.balance.toString(), decimals);      
    } catch (error) {
      console.error("Invalid input for parseUnits:", userData, error);
      return "0"; // or handle error case
    }
  };

  const handleMintTicket = () => {
    // setActiveSavingTab("createSavingAccount");
    navigate("/ticket");
  };

  const handleMintCallback = (mintResult) => {
    console.log('Mint result:', mintResult);
    refetchUserData();
    refetchBalance();
    setIsReloadSavingAccount(true);    
  };

  const setReloadComplete = () => {
    setIsReloadSavingAccount(false);
  }

  // Handle moving to the previous NFT
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handle moving to the next NFT
  const handleNext = () => {
    if (currentIndex < nfts.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const fetchNfts = async () => {
      try {
        const response = await fetch(
          `https://testnets-api.opensea.io/api/v2/chain/blast_sepolia/account/${address}/nfts?collection=save-together-ticket-nft`,
          {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              'x-api-key': '2c86a9cd380f437e9477657b887315c2',
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("nft data:", data);
        setNfts(data.nfts || []); // Assuming `nfts` is the key in the response that contains the NFT data
      } catch (err) {
        setError(err.message);
      }
    };

    if(isConnected) {
      fetchNfts();
    }
  }, []);

  useEffect(() => {   
    console.log("allGameInfoData: ", allGameInfoData);
    console.log("nfts: ", nfts);
    if (allGameInfoData && nfts) {
      //Process Prize Match For Game and Ticket here
               
    }
  }, [allGameInfoData], [nfts]);

  function formatDate(timestamp) {
    // Convert the timestamp to milliseconds if it's in seconds
    const date = new Date(timestamp * 1000);
  
    // Extract the year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
  
    // Return in 'Year.Month.Day' format
    return `${year}.${month}.${day}`;
  }

  function sortNumbers(numbers) {    
    const resultNumbers = numbers.map(Number);
    const firstFive = resultNumbers.slice(0, 5);
    const sortedFirstFive = firstFive.sort((a, b) => (a > b ? 1 : -1));
    return sortedFirstFive.concat(numbers.slice(5));    
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!isConnected) {
    return <div className="mt-16 text-center text-xl flex flex-col w-full gap-3 mb-30">Please login with your wallet.</div>;
  }

  return (    
    <div className="flex flex-col pt-16 w-full h-min-screen gap-3 mb-20 md:mb-0">      
      <div className="w-full flex flex-col shadow-md rounded-lg text-right gap-0 p-3 bg-[#003b79]">                                             
          <div className='flex flex-row  gap-3  m-3' >  
            <button
              className={` bg-[#cd0039] text-2xl font-bold text-center text-white p-3 rounded-lg shadow-lg ${
                currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePrevious}
              disabled={currentIndex === 0}
            >
              &#8592;
            </button>

            <div className="text-lg sm:text-xl text-center font-bold text-white rounded-lg p-1  md:p-1 w-full">  
              <p className="hidden md:block">Total Portfolio: {nfts? nfts.length * 100: 0} USDB</p>  
              <div className="md:hidden">
                <p>Total Portfolio</p>
                <p>{nfts? nfts.length * 100: 0} USDB</p>  
              </div>
              <h2 className="font-medium">Ticket {nfts.length > 0?currentIndex+1:currentIndex}/{nfts.length}</h2>
            </div>
            
            <button
              className={`bg-[#cd0039] text-2xl font-bold text-white p-3 rounded-lg shadow-lg ${
                currentIndex === nfts.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleNext}
              disabled={currentIndex === nfts.length - 1}
            >
              &#8594;
            </button>
          </div>          
      </div>  
      {nfts.length > 0 ? (
      <div className="flex grid grid-cols-1 flex sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 items-center rounded-lg space-y-3 md:space-y-0 md:gap-3 text-lg">
        <div id="nft" className=" border-2  rounded-lg w-full flex flex-col items-center justify-center">          
          <div className="aspect-square w-full text-lg gap-1 flex items-center justify-center rounded">                                      
            {/* <svg width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="#003b79" />
              <polygon transform="translate(130, 200) scale(0.28)" points="100,20 130,75 200,75 145,120 160,180 100,150 40,180 55,120 0,75 70,75" fill="#fff" />
              <polygon transform="translate(790, 200) scale(0.28)" points="100,20 130,75 200,75 145,120 160,180 100,150 40,180 55,120 0,75 70,75" fill="#fff" />
              <polygon points="50,100 100,50 900,50 950,100 950,900 900,950 100,950 50,900" fill="none" stroke="white" strokeWidth="10"/>
              <text x="500" y="250" fontSize="60" fontWeight="bold" textAnchor="middle" fill="#FFF">POWERCOIN</text>
              <line x1="90" y1="400" x2="910" y2="400" stroke="#FFF" strokeWidth="7"/><line x1="90" y1="600" x2="910" y2="600" stroke="#FFF"  strokeWidth="7"/>
              <rect x="90" y="410" width="820" height="180" rx="20" stroke="#fff" strokeWidth="7" fill="#cd0039"/>
              <text x="500" y="530" fontSize="81" fontWeight="bold" textAnchor="middle" fill="WHITE">Free To Play Lottery</text>
              <text x="500" y="750" fontSize="60" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#fff">100 USDB</text>              
            </svg>                                    */}
            {/* <text x="90" y="900" fontSize="35" textAnchor="start" fill="white">ID: TBD</text><text x="910" y="900" fontSize="35" textAnchor="end" fill="white">Supply: 100</text> */}
            <img src={nfts[currentIndex]?.image_url} alt={nfts[currentIndex]?.name} />
            {/* Navigation buttons positioned on top of the image */}
          
          </div>
          <div className=" flex text-sm flex-row flex w-full gap-3 mt-0.5 ">
            <button className={"text-white bg-OldRed  flex-1 p-2  text-center rounded-lg hover:bg-red-500"} 
              onClick={() => handleRedeem(nfts[currentIndex]?.identifier)}>
              Redeem
            </button>  
            
{/*             
            <button className={"text-white bg-OldRed  p-2 flex-1  text-center rounded-lg hover:bg-red-500"} 
              onClick={() => handleChangeNumbers()}>
              Change Numbers
            </button>             */}
          </div>
          
          {/* <div className="absolute top-9 left-24 flex text-sm flex-row w-full gap-3 mt-1 opacity-70">
            <button className={"text-white bg-OldRed w-32 p-2 flex justify-center items-center  text-center rounded-lg hover:bg-red-500"} 
              onClick={() => handleRedeem(nfts[currentIndex]?.identifier)}>
              Opensea Icon
            </button>  
            <button className={"text-white bg-OldRed w-32 p-2 flex justify-center items-center  text-center rounded-lg hover:bg-red-500"} 
              onClick={() => handleChangeNumbers()}>
              Blur Icon
            </button>            
          </div> */}
        </div>  

        <div className="flex flex-col w-full col-span-2 h-full border-2 rounded-lg mt-10 p-1" >
          <div id="Reward" className="flex flex-col w-full h-full hidden lg:block col-span-2  items-center text-center  ">
            <table className="w-full text-sm md:text-lg  text-center rounded-lg">
              <thead>
                <tr className="text-center text-sm md:text-lg border-2 bg-[#003b79] text-white rounded-lg">
                  <th className="p-2">Game</th>
                  <th className="p-2">Match</th>
                  <th className="p-2">Prize</th>
                  <th className="p-2">Prize Payout</th>
                </tr>
              </thead>
              <tbody className="text-black">
              {gamesInfo?.map(game => {                                
                const resultNumbers = game?.resultNumbers?.map(Number);
                const ticketNumbers = nfts[currentIndex]?.name.split("-").map(Number);  
                // console.log("resultNumbers:", resultNumbers); 
                // console.log("ticketNumbers:", ticketNumbers);
                return ( 
                   (game.isPerformed && ticketNumbers)? (
                    <tr className="text-sm md:text-lg border-b text-center hover:bg-blue-400">
                      <td className="p-2">
                        <p>{formatDate(Number(game.drawTime)).toString()}</p>
                        
                      </td>
                      <td className="p-2">                        
                        <NumberComparison resultNumbers={sortNumbers(game.resultNumbers).map(Number)} ticketNumbers={ticketNumbers}></NumberComparison>                        
                      </td>
                      <td className="p-2 text-left">
                        <p>{getPrize(ticketNumbers, resultNumbers, game.prizeInfo) === 9? (
                              "No Prize"
                            ) :(Number(getPrize(ticketNumbers, resultNumbers, game.prizeInfo))                     
                          )}
                        </p>                        
                      </td>
                      <td className="p-2">
                        Airdrop
                      </td>
                    </tr>
                    ): (
                      <tr className="text-sm md:text-lg border-b text-center hover:bg-blue-400">
                        <td className="p-2">
                          <p>{formatDate(Number(game.drawTime)).toString()}</p>
                        </td>
                        <td className="p-2">
                          Lottery not drawn yet
                        </td>
                        <td className="p-2 text-left">
                          <p>N/A</p>
                        </td>
                        <td className="p-2">
                          Airdrop
                        </td>
                      </tr>
                    )
                  )                  
              })}                
              </tbody>
            </table>                    
          </div>

          {/*Mobile Display*/}    
          <div className="block lg:hidden text-black space-y-3">
              <p className='text-xl text-black text-center font-bold'>Reward</p>
              {gamesInfo?.map(game => {                                
                const resultNumbers = game?.resultNumbers?.map(Number);
                const ticketNumbers = nfts[currentIndex]?.name.split("-").map(Number);  
                // console.log("resultNumbers:", resultNumbers); 
                // console.log("ticketNumbers:", ticketNumbers);
                return ( 
                   (game.isPerformed && ticketNumbers)? (
                    <div className="border rounded-lg p-3 flex flex-col gap-1 bg-gradient-to-b from-[#0487E2] to-blue-900 text-white">
                      <p className="border-b">Game: {formatDate(Number(game.drawTime)).toString()}</p>
                      <div className='border-b flex flex-row items-center gap-3'>
                        {/* <p>Match:</p> */}
                        <NumberComparison resultNumbers={sortNumbers(game.resultNumbers).map(Number)} ticketNumbers={ticketNumbers}></NumberComparison>
                      </div>
                      <p className="border-b">Prize: {getPrize(ticketNumbers, resultNumbers, game.prizeInfo) === 9? (
                              "No Prize"
                            ) :(Number(getPrize(ticketNumbers, resultNumbers, game.prizeInfo))                     
                          )}</p> 
                      <p className="">Prize Payout: Airdrop</p>                          
                    </div>                                        
                    ): (
                      <div className="border rounded-lg p-3 flex flex-col gap-1 bg-gradient-to-b from-[#0487E2] to-blue-900 text-white">
                        <p className="border-b">Game: {formatDate(Number(game.drawTime)).toString()}</p>
                        <div className='border-b flex flex-row items-center gap-3'>
                          <p>Match:</p>
                          Lottery not drawn yet
                        </div>
                        <p className="border-b">Prize: N/A</p> 
                        <p className="">Prize Payout: Airdrop</p>                          
                      </div>                                            
                    )
                  )                  
              })}              
          </div>    
        </div>                
      </div>      
      ) : (
        <div>
          <button                    
            className={"text-white bg-[#cd0039] h-14 p-2 flex justify-center items-center  text-center rounded-lg w-full hover:bg-red-500"}
            onClick={handleMintTicket}            
          >
            Save To Earn Free Lotto Ticket NFT
          </button>
        </div>
      )}
    
    </div>    
  );
};

export default UnisaveAccountsPage;
