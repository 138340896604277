import React from "react";
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import PowercoinButton from "./PowercoinButton.jsx";
import { FaHome, FaChartLine, FaTrophy, FaWallet, FaEllipsisH } from 'react-icons/fa';
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiAbstract055, GiPresent } from "react-icons/gi";
import BridgeMenu from "./BridgeMenu.jsx";
import { FaBridge } from "react-icons/fa6";
import { GiArchBridge } from "react-icons/gi";

function Navbar() {
  const [isActive, setIsActive] = useState(true);
  const location = useLocation();
  const isGamesActive = location.pathname.startsWith('/games');
  const isTicketActive = location.pathname.startsWith('/ticket');
  const isPortfolioActive = location.pathname.startsWith('/portfolio');

  const getActiveStyle = ({ isActive }) =>
    isActive ? "text-black font-bold" : "text-gray-500 hover:text-black";

  return (
    <>
      {/* Top Navigation Bar */}
      <nav className="fixed top-0 left-0 p-3 z-50 right-0 px-3 md:px-7 lg:px-10 bg-white text-lg">
      
        <div className="container mx-auto flex items-center w-full justify-between">          
          <div className="container flex flex-row space-x-7">
            <PowercoinButton/>
            <div className="hidden md:flex space-x-3">
              <Link to="/ticket" className={`${isTicketActive?"text-blue-500":"text-gray-700 hover:text-blue-500"}`}>Save</Link>
              <Link to="/games" className={`${isGamesActive?"text-blue-500":"text-gray-700 hover:text-blue-500"}`}>Prize</Link>              
              <Link to="/portfolio" className={`${isPortfolioActive?"text-blue-500":"text-gray-700 hover:text-blue-500"}`}>Earn</Link>            
              <BridgeMenu></BridgeMenu>
            </div>
            
          </div>
          <div className="w-auto md:w-56">
            <ConnectButton chainStatus="icon" showBalance={false}  label="Login"  
                accountStatus={{
                    smallScreen: 'avatar',
                    largeScreen: 'full',
                 }}>
            </ConnectButton>
          </div>
        </div>        
      </nav>

      {/* Bottom Navigation Bar for Mobile */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-md p-3 flex justify-around z-50">
      <Link to="/ticket" className={`${isTicketActive?"text-blue-500":"text-gray-700 hover:text-blue-500"}`}>
          <div className="flex flex-col items-center cursor-pointer p-1">
            <FaMoneyBillTrendUp className="md:hidden w-6 h-6" />
            <span className="text-xs md:text-base">Save</span>
          </div>
        </Link>  
        <Link to="/games" className={`${isGamesActive?"text-blue-500":"text-gray-700 hover:text-blue-500"}`}>
          <div className="flex flex-col items-center cursor-pointer p-1">
            <GiAbstract055 className="md:hidden w-6 h-6" />
            <span className="text-xs md:text-base">Prize</span>
          </div>
        </Link>

        

        <Link to="/portfolio" className={`${isPortfolioActive?"text-blue-500":"text-gray-700 hover:text-blue-500"}`}>
          <div className="flex flex-col items-center cursor-pointer p-1">
            <FaWallet className="md:hidden w-6 h-6" />
            <span className="text-xs md:text-base">Earn</span>
          </div>
        </Link>     
        <div className="flex flex-col items-center cursor-pointer p-1">
          <FaBridge className="md:hidden w-6 h-6"></FaBridge>
          <BridgeMenu></BridgeMenu>  
        </div>        
      </div>
    </>
  );
}

export default Navbar;