import React, { useState, useRef, useEffect } from 'react';
import { FiExternalLink } from 'react-icons/fi';

const BridgeButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Ref to track the dropdown menu

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

    // Close the dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
            }
        };

        // Add event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        }, []);

  return (
    <div className="relative w-full inline-block text-left" ref={menuRef}>
      {/* More button */}
      <button
        onClick={toggleDropdown}
        className="inline-flex text-base justify-center bg-OldRed w-full text-white items-center hover:text-red-500 rounded-lg p-3"
      >
        <div>Insufficient USDB. Bridge To Blast Here</div>
        {/* <svg
          className={`ml-1 mb-1 h-4 w-4 transform transition-transform ${
            isOpen ? 'rotate-0' : 'rotate-180'
          }`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707A1 1 0 014.293 8.293l5-5A1 1 0 0110 3z"
            clipRule="evenodd"
          />
        </svg> */}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-0 mb-0 bottom-full md:mt-2 w-60 bg-gray-100 border border-gray-200 rounded-lg shadow-lg">
          <div className="py-1">                               
            <a
              href="https://app.across.to/bridge?"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex flex-col items-center">                
                <p>From Arbitrum To Blast</p>
                <p className='text-sm'>DAI to USDB</p>
              </div>              
              <FiExternalLink />
            </a>
            <a
              href="https://app.across.to/bridge?"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex flex-col items-center">                
                <p>From Base To Blast</p>
                <p className='text-sm'>DAI to USDB</p>
              </div>              
              <FiExternalLink />
            </a>
            <a
              href="https://blast.io/en/bridge"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex flex-col items-center">                
                <p>From Ethereum To Blast</p>
                <p className='text-sm'>USDC/USDT to USDB</p>
              </div>
              <FiExternalLink />
            </a>
            <a
              href="https://blast.io/en/bridge"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex flex-col items-center">                
                <p>From CEXs To Blast</p>
                <p className='text-sm'>USDC/USDT to USDB</p>
              </div>
              <FiExternalLink />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BridgeButton;
