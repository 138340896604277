import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


const GameMenu = ({ _activeTab }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(_activeTab);
    // console.log("activeTab: ", activeTab);

    const handleActiveTab = (tab) => {
        // console.log("active tab:", tab);
        setActiveTab(tab);
        navigate("/games/"+tab);
    };
    
    return (
      <div>
        <div className="text-3xl sm:text-2xl text-center">Games</div>
        <div className="tab-menu border-b text-base sm:text-xl mb-1 gap-5 text-black flex overflow-x-auto whitespace-nowrap">
          <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "Upcoming" ? "text-black border-b-2 border-blue-500" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("Upcoming")}
          >
            Upcoming
          </button>
          <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "Schedules" ? "text-black border-b-2 border-blue-500" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("Schedules")}
          >
            Schedules
          </button>
          <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "Results" ? "text-black border-b-2 border-blue-500" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("Results")}
          >
            Results
          </button>
          

          {/* <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "results" ? "text-black" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("results")}
          >
            Results
          </button> */}
          {/* <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "compare" ? "text-black" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("compare")}
          >
            Compare Numbers
          </button>           */}
          {/* <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "winnerStories" ? "text-black" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("winnerStories")}
          >
            Winner Stories
          </button> */}
          {/* <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "winnerStories" ? "text-black" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("winnerStories")}
          >
            Odds
          </button> */}
        </div>
      </div>
    )

};
export default GameMenu;