import GameInfo from "../components/GameInfo.jsx";
import { unigameContractConfig } from "../contract.js";
import GameMenu from "../components/GameMenu.jsx"
import React, { useEffect, useState } from "react";
import { useAccount, useReadContract, useInfiniteReadContracts } from "wagmi";
import NumberDisplay from "../components/NumberDisplay.jsx";
import { useNavigate } from "react-router-dom";

const GameResultsPage = () => {
  const ITEMS_PER_PAGE = 5;
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const [games, setGames] = useState([]);
  const [totalGames, setTotalGames] = useState(0);
  const [fetchKey, setFetchKey] = useState(0); // Add a key to force re-render and refetch

  // Fetch the total number of games from the contract by reading nextGameId
  const { data: currentGameIdData, isLoading: isNextGameIdLoading } =
    useReadContract({
      ...unigameContractConfig,
      functionName: "currentGameId", // Read the public variable nextGameId
    });

  const nextGameId = currentGameIdData ? Number(currentGameIdData) : 0;

  
  const handleViewResult = () => {
    navigate("/gameResults");    
  };

  useEffect(() => {
    console.log("currentGameIdData:", currentGameIdData);
    if (currentGameIdData) {
      const calculatedTotalGames = Number(currentGameIdData);
      if (calculatedTotalGames !== totalGames) {
        setTotalGames(calculatedTotalGames);
        setFetchKey((prevKey) => prevKey + 1); // Force refetch by changing the key
      }
    }
  }, [currentGameIdData, totalGames]);

  // Fetch games data using useInfiniteReadContracts
  const { data, isLoading, isError, fetchNextPage, hasNextPage } =
    useInfiniteReadContracts({
      key: fetchKey, // Use fetchKey to trigger re-fetch when totalGames changes
      cacheKey: "unigame",
      contracts: (pageParam) => {
        const startIndex = pageParam * ITEMS_PER_PAGE;
        const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, totalGames); // Ensure we don’t fetch more than available

        const contracts = [];
        for (let i = startIndex; i < endIndex; i++) {
          contracts.push({
            ...unigameContractConfig,
            functionName: "getGame",
            args: [i + 1], // Game IDs are 1-indexed
          });
        }

        return contracts;
      },
      query: {
        initialPageParam: 0,
        getNextPageParam: (_lastPage, allPages) => {
          const totalFetched = allPages.flatMap((page) => page).length;
          return totalFetched < totalGames ? totalFetched / ITEMS_PER_PAGE : undefined;
        },
      },
      enabled: totalGames > 0, // Only fetch games when totalGames is known
    });

  useEffect(() => {
    if (data) {      
      const newGames = data.pages.flatMap((page) => {
        return page.map((gameData) => {
          const game = gameData.result;
          console.log("game: ", game);
          const resultNumbers = game[1].map(Number);          
          
          return {
            id: Number(game[0]),
            resultNumbers,                      
            time: new Date(Number(game[2]) * 1000).toLocaleString(),
            gameCategory: game[3],
            maxUnisaveIDForEntry: Number(game[4]),
            prizeTokenAddress: game[5],
            isPerformed: game[6],
          };
        });
      });
      setGames((prevGames) => [...prevGames, ...newGames]);
    }
  }, [data]);

  // if (!isConnected) {
  //   return <div>Please connect your wallet to view games.</div>;
  // }

  // if (isLoading || isNextGameIdLoading) {
  //   return <div>Loading games...</div>;
  // }

  // if (isError) {
  //   return <div>Error fetching games data.</div>;
  // }

  return (
    <div>      
      <GameMenu _activeTab="Results"></GameMenu> 
      {(isLoading || isNextGameIdLoading) && (<div>Loading games...</div>)}
      {(isError) && (<div>Error fetching games data.</div>)}

      {(!isLoading && !isNextGameIdLoading && !isError) && (
      <div>
        {games.length === 0 ? (
          <div>No game found.</div>
        ) : (
          <div className="py-3">
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
              {games.map((game, index) => (
                <li
                  key={index}
                  className="bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg text-white flex flex-col items-center gap-y-3 text-center text-base md:text-lg border p-4 rounded-lg"
                >
                  <h2 className="text-lg sm:text-xl font-bold bg-white text-red-600 rounded-lg p-2 w-4/5 text-center">
                    Game ID: {game.id.toString()}
                  </h2>
                  <p className="">{game.time}</p>
    
                  <NumberDisplay numbers={game.resultNumbers} />
                  <p className="">Big Bang Airdrop</p>
                      
                  <button
                    className="text-white text-base bg-[#cd0039] p-2 hover:bg-red-500 text-center rounded-lg w-full"
                    onClick={handleViewResult}
                  >
                    View Results
                  </button>                
                </li>
              ))}
            </ul>
          </div>
        )}
        {hasNextPage && (
          <button
            className="mt-4 p-2 bg-blue-500 text-white rounded"
            onClick={() => fetchNextPage()}
          >
            Load More
          </button>
        )}
      </div>
      )}
    </div>    
  );
};

export default GameResultsPage;
