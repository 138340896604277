// src/pages/HomePage.tsx
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const handleSaveClick = () => {
    navigate("/newSaving"); // Navigate to the Mint Unisave page
  };

  return (
    <div className="bg-white text-gray-900 px-3">
      {/* Hero Section */}
      <section className="flex flex-col flex-col-reverse h-min-screen gap-3 md:flex-row pt-20 items-center border-b py-7">
        <div className="md:flex-1 text-center md:text-left">
          <h1 className="text-3xl md:text-5xl font-bold mb-6 text-OldBlue">
            Make Saving Fun and Rewarding
          </h1>
          <p className="text-xl sm:text-lg md:text-2xl mb-6 md:mb-10">
            There is <span className="font-bold">$5 trillions</span> currently sitting at US Bank saving accounts earning close to <span className="font-bold">0%</span>. We can make saving better, <span className="font-bold">more fun & rewarding</span> than that.
          </p>
          <Link
            to="/ticket"
            className="bg-OldRed text-base sm:text-lg md:text-xl text-white font-semibold p-3 mb-3 rounded-lg shadow-lg hover:bg-red-500"
          >
            Save together, have fun, & win prizes
          </Link>
        </div>
        <div className="md:flex-1">
          <img className="w-full h-auto" src="./Award.jpg" alt="Hero Image" />
        </div>
      </section>
  
      {/* Save To Earn Section */}
      <section id="save-to-earn" className="border-b py-7 flex md:flex-row flex-col flex-col-reverse gap-3">
        <div className="container mx-auto text-center ">
          <h2 className="text-3xl md:text-5xl font-bold mb-6 sm:mb-10 text-OldBlue">Save To Earn</h2>
          <div className="flex flex-col md:flex-row gap-6 md:gap-8 justify-center items-center">
            <div className="rounded-lg border-2 p-6 md:p-10 border-red-500 hover:shadow-lg transition duration-300">
              <h3 className="text-base sm:text-lg md:text-xl"><span className="font-bold">Step 1</span>: Save your money into a SaveTogether NFT that has unique ticket numbers.</h3>
            </div>
            <div className="h-full rounded-lg border-2 p-6 md:p-10 border-red-500 hover:shadow-lg transition duration-300">
              <h3 className="text-base sm:text-lg md:text-xl"><span className="font-bold">Step 2</span>: Watch the lottery draws and compare your ticket numbers.</h3>
            </div>
            <div className=" h-full rounded-lg border-2 p-6 md:p-10 border-red-500 hover:shadow-lg transition duration-300">
              <h3 className="text-base sm:text-lg md:text-xl"><span className="font-bold">Step 3</span>: Check your winnings and claim your rewards.</h3>
            </div>
          </div>
          <div className="mt-8 sm:mt-12">
            <Link 
              to="/ticket"
              className="bg-OldRed text-white font-semibold py-3 px-8 sm:px-10 rounded-lg hover:bg-red-500 transition-all duration-300">
              Save To Earn Free Lotto Ticket NFT
            </Link>
          </div>
        </div>
        <div className="">
          <img className="w-full h-auto" src="./Saving.jpg" alt="Hero Image" />
        </div>
      </section>
  
      {/* No Loss, High Reward Section */}
      <section id="no-loss" className="bg-white mt-3 border-b py-7 flex md:flex-row flex-col flex-col-reverse gap-3">
        <div className="container mx-auto text-center px-5">
          <h2 className="text-3xl md:text-5xl font-bold mb-7 sm:mb-10 text-OldBlue">No Loss. High Reward.</h2>
          <p className="text-base sm:text-lg md:text-xl mb-6 sm:mb-8">
            Keep your deposit. Win big prizes. Draw happens everyweek.
          </p>
          <a href="https://savetogether.gitbook.io/savetogether" className="bg-OldRed text-white font-semibold p-3 rounded-lg hover:bg-red-500 transition-all duration-300">
          Learn How SaveTogether Works
          </a>
        </div>
        <div className="">
          <img className="w-full h-auto" src="./RiskReward.jpg" alt="Hero Image" />
        </div>
      </section>
  
      {/* Footer */}
      <footer className=" py-8 sm:py-10">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 SaveTogether. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
