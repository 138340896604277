import "@rainbow-me/rainbowkit/styles.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { RainbowKitProvider, darkTheme, lightTheme, connectorsForWallets   } from "@rainbow-me/rainbowkit";
import { walletConnectWallet, metaMaskWallet } from '@rainbow-me/rainbowkit/wallets';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http, createConfig } from "wagmi";
import {
  // mainnet,
  // sepolia,
  // blast,
  blastSepolia,
  // base,
  // baseSepolia,  
  // arbitrum,
  // arbitrumSepolia,
} from "wagmi/chains";

import App from "./App";
import client from './apollo-client';
import { ApolloProvider } from '@apollo/client';

const configNew = getDefaultConfig({
  appName: 'Powercoin',
  projectId: 'f56e8a20ffb4dfdb47ac398f4ca619de',
  chains: [blastSepolia],
  transports: {
    // [blast.id]: http(),
    [blastSepolia.id]: http(),
    // [arbitrumSepolia.id]: http(),
  },
})

const root = ReactDOM.createRoot(
  document.getElementById("root"),
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <WagmiProvider config={configNew}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <RainbowKitProvider theme={lightTheme({
        accentColor: '#cd0039',      
      })}>
            <App/>
          </RainbowKitProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);
