import React, { useState, useEffect } from 'react';
import { useAccount, useWriteContract, useReadContract, useWaitForTransactionReceipt } from 'wagmi';
import JackpotMenu from "../components/JackpotMenu.jsx";
import { prizeManagerContractConfig, usdbContractConfig } from '../contract.js';
import TopMenu from "../components/TopMenu.jsx";
import { parseUnits } from "viem";
import CountdownTimer from "../components/CountdownTimer.jsx";


const SponsorPage = () => {
  const [activeTab, setActiveTab] = useState('Assets');
  const {address:connectedAddress, isConnected } = useAccount();

  const [currentJackpotId, setCurrentJackpotId] = useState(null);

  const [erc20SponsorName, setErc20SponsorName] = useState('');
  const [erc20SponsorWebsite, setErc20SponsorWebsite] = useState('');
  const [erc20SponsorToken, setErc20SponsorToken] = useState('');
  const [erc20SponsorAmount, setErc20SponsorAmount] = useState('');
  const [sponsorERC20Hash, setSponsorERC20Hash] = useState(null);
  

  const { writeContract: approveToken } = useWriteContract();

  const {
    data: sponsorERC20HashData,
    isError: isSponsorERC20Error,
    isSuccess: isSponsorERC20Success,
    isLoading: isSponsorERC20Loading,
    writeContract: sponsorERC20,
  } = useWriteContract();

  // Wait for transaction receipt when DepositHash updates
  const { data, isError: isReceiptERC20DepositError, isSuccess: isReceiptERC20DepositSuccess} = useWaitForTransactionReceipt({
    hash: sponsorERC20Hash,
  });

  useEffect(() => {        
    console.log('sponsorERC20HashData:', sponsorERC20HashData);       
    setSponsorERC20Hash(sponsorERC20HashData);             
  }, [sponsorERC20HashData]);  

  const { data: currentJackpotIdData, refetch: refetchCurrentJackpotData } = useReadContract({      
    ...prizeManagerContractConfig,  
    functionName: "jackpotTokenIdCounter",
  });

  useEffect(() => {        
    console.log('currentJackpotIdData:', currentJackpotIdData);   
    setCurrentJackpotId(currentJackpotIdData);          
  }, [currentJackpotIdData]); 

  const { data: tokenAllowance, refetch: refetchTokenAllowance } = useReadContract({      
    address: erc20SponsorToken, //erc20SponsorToken,
    abi: usdbContractConfig.abi,  //Temporary use USDB Token ABI for other token.
    functionName: "allowance",
    args: [connectedAddress, prizeManagerContractConfig.address],
  });

  const { data: tokenDecimals, refetch: refetchTokenDecimals } = useReadContract({      
    address: erc20SponsorToken, //erc20SponsorToken,
    abi: usdbContractConfig.abi,  //Temporary use USDB Token ABI for other token.
    functionName: "decimals",    
  });

  

  useEffect(() => {    
    if (erc20SponsorToken) {
      console.log('erc20SponsorToken refetchTokenAllowance:', erc20SponsorToken);
      refetchTokenAllowance();    
      refetchTokenDecimals();      
      return;
    }       
  }, [erc20SponsorToken]);

  let needsApproval =
    tokenAllowance !== undefined &&
    BigInt(tokenAllowance) < BigInt(erc20SponsorAmount);

  useEffect(() => {    
    console.log('tokenAllowance:', tokenAllowance);         
    needsApproval =
      tokenAllowance !== undefined &&
      BigInt(tokenAllowance) < BigInt(erc20SponsorAmount);    
    console.log('needsApproval:', needsApproval);
    return;  
  }, [tokenAllowance, erc20SponsorAmount]);



  const handleSponsorERC20 = () => {
    console.log('connectedAddress:', connectedAddress);     
        
    const parseSponsorAmount = parseUnits(erc20SponsorAmount, tokenDecimals);   

    if (needsApproval) {
      approveToken({
        address: erc20SponsorToken,
        abi: usdbContractConfig.abi,
        functionName: "approve",
        args: [prizeManagerContractConfig.address, parseSponsorAmount],
      });
    }    
  
    sponsorERC20({
      ...prizeManagerContractConfig,
      functionName: "sponsorERC20",
      args: [
        currentJackpotId,  
        erc20SponsorName,
        erc20SponsorWebsite,      
        erc20SponsorToken,
        parseSponsorAmount,        
      ],
    });     
  };

  const handleSponsorERC721 = () => {
    console.log("handleSponsorERC721:");
    
  };

  return (
    <div className="min-h-screen mb-20 flex flex-col">      
      <JackpotMenu _activeTab={"sponsor"} />
      <div className="flex gap-3 items-center grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 ">   
        <div className="flex flex-col gap-3 text-white h-full flex-1 items-center justify-center p-5 text-center bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg">
          <h2 className="text-lg sm:text-xl font-bold bg-white text-red-600 rounded-lg p-2 w-full">
            Jackpot ID: {currentJackpotId?.toLocaleString()}
          </h2>
          
          <div className="">
            <p className="text-base sm:text-lg">Total Asset Sponsors</p>
            <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg">20</p>
          </div>
          <div className="">
            <p className="text-base sm:text-lg">Total Collectible Sponsors</p>
            <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg">30</p>
          </div>
          <div className="">
            <p className="text-base sm:text-lg">Estimated Jackpot Value</p>
            <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg p-2">$10,000,000 USDB</p>
          </div>
                    
        </div>
        <div className="flex flex-col gap-3 text-white h-full flex-1 items-center p-5 text-center bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg">          
          {/* Tabs: Collectibles and Assets */}
          <div className="border-b mb-4 w-full scrollbar-hide whitespace-nowrap">          
            <button
              className={`w-1/2 py-2 flex-shrink-0 ${activeTab === 'Assets' ? 'border-b-2 border-red-600' : 'text-gray-300'}`}
              onClick={() => setActiveTab('Assets')}
            >
              Sponsor Asset
            </button>

            <button
              className={`w-1/2 py-2 flex-shrink-0 ${activeTab === 'Collectibles' ? 'border-b-2 border-red-600' : 'text-gray-300'}`}
              onClick={() => setActiveTab('Collectibles')}
            >
              Sponsor Collectible
            </button>          
          </div>

          {/* Tab Content */}
          <div className="w-full">
          {activeTab === 'Assets' ? (
            <div className=''>
            {/* Assets Content */}              
              <div className='flex flex-col items-center gap-3 justify-stretch'>
                <input id="erc20SponsorName" placeholder='Sponsor Name' className='text-center border-2 w-full p-2 rounded'
                  value={erc20SponsorName} 
                  onChange={(e) => setErc20SponsorName(e.target.value)}>                            
                </input>
                <input id= "erc20SponsorWebsite" placeholder='Sponsor Website' className='text-center border-2 w-full p-2 rounded'
                  value={erc20SponsorWebsite} 
                  onChange={(e) => setErc20SponsorWebsite(e.target.value)}
                ></input>
                <input id= "erc20SponsorToken" placeholder='ERC20 Token' className='text-center border-2 w-full p-2 rounded'
                  value={erc20SponsorToken}
                  onChange={(e) => setErc20SponsorToken(e.target.value)}>
                </input>
                <input id= "erc20SponsorAmount" placeholder='Amount' className='text-center border-2 w-full p-2 rounded'
                  value={erc20SponsorAmount}
                  onChange={(e) => setErc20SponsorAmount(e.target.value)}>                
                </input>            
              </div>
              <button className='bg-red-600 w-full mt-3 text-white p-2 rounded hover:bg-red-500' 
                onClick={handleSponsorERC20}>
              Sponsor
              </button>

              {/* Sponsor Results */}
              <div>                  
                {isReceiptERC20DepositSuccess && <p className="text-green-600">Sponsor successful!</p>}
                {isReceiptERC20DepositError && <p className="text-red-600">Error sponsoring the Jackpot. Please try again.</p>}   
              </div>                               
            </div>              
          ) : (
            <div>
              {/* Collectibles Content */}
              <div className='flex flex-col items-center gap-3'>
                <input placeholder='Sponsor Name' id="erc721SponsorName" className='text-center border-2 w-full p-2 rounded'></input>
                <input placeholder='Sponsor Website' className='text-center border-2 w-full p-2 rounded'></input>
                <input placeholder='ERC721 Token' className='text-center border-2 w-full p-2 rounded'></input>
                <input placeholder='Token ID' className='text-center border-2 w-full p-2 rounded'></input>            
              </div>
              <button className='bg-red-600 w-full mt-3 text-white p-2 rounded hover:bg-red-500' 
                onClick={handleSponsorERC721}>
                
                Sponsor</button>
            </div>
          )}
          </div>    
        </div>    
      </div>
    </div>
  );
};

export default SponsorPage;
