import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TopMenu from "../components/TopMenu.jsx";

const JackpotMenu = ({ _activeTab }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(_activeTab);
    // console.log("activeTab: ", activeTab);

    const handleActiveTab = (tab) => {
        // console.log("active tab:", tab);
        setActiveTab(tab);
        navigate("/"+tab);
    };
    
    return (
      <div>
        {/* <TopMenu/> */}
        <div className="text-3xl sm:text-2xl text-center">Jackpots</div>
        <div className="tab-menu border-b text-base sm:text-xl mb-1 gap-5 text-black flex overflow-x-auto scrollbar-hide whitespace-nowrap">
          <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "currentJackpot" ? "text-black border-b-2 border-blue-500" : "text-gray-400"              
            } hover:text-black`}
            onClick={() => handleActiveTab("currentJackpot")}
          >
            Current Jackpot
          </button>
          
          <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "sponsor" ? "text-black border-b-2 border-blue-500" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("sponsor")}
          >
            Sponsor Jackpot
          </button>
          <button
            className={`tab-button flex-shrink-0 ${
              activeTab === "previousJackpots" ? "text-black border-b-2 border-blue-500" : "text-gray-400"
            } hover:text-black`}
            onClick={() => handleActiveTab("previousJackpots")}
          >
            Previous Jackpots
          </button>          
        </div>
      </div>
    )

};
export default JackpotMenu;