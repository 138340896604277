import React, { useState, useEffect } from 'react';
import { useReadContract } from 'wagmi';
import { ethers } from 'ethers';
import { unigameContractConfig, tokenSymbols } from "../contract.js";
import { formatUnits } from 'viem';
import CountdownTimer from "../components/CountdownTimer.jsx";
import { useNavigate } from "react-router-dom";

import PrizeDisplay from "../components/PrizeDisplay.jsx";
import NumberDisplay from "../components/NumberDisplay.jsx";
import GameMenu from "../components/GameMenu.jsx"
import { FaTwitter } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';

// Mapping PrizeTier enum values to human-readable strings
const PRIZE_TIER_LABELS = [
    "Match 5 + Unisave",
    'Match 5',
    'Match 4 + Unisave',
    'Match 4',
    'Match 3 + Unisave',
    'Match 3',
    'Match 2 + Unisave',
    // 'Match 2',
    'Match 1 + Unisave',
    'Match Unisave'
    // 'Match 1'
  ];


  const GamesPage = () => {
    const navigate = useNavigate();
    // const [gameId, setGameId] = useState(1); // Example gameId, you can update this as needed
    const [nextGameId, setNextGameId] = useState(0); // State for nextGameId
    const [nextGamePrize, setNextGamePrize] = useState(null);
    const [nextGame, setNextGame] = useState(null); // State to store game data
    const [currentGame, setCurrentGame] = useState(null); // State to store game data
    const [currentPrize, setCurrentGamePrize] = useState(null);
    const [isHowOpen, setHowOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("listSavingAccounts");
    const [currentGameIndex, setCurrentGameIndex] = useState(0);

    // return (      
    //   <div className="flex flex-col mt-3 h-min-screen  justify-center items-center mb-20 md:mb-0">     
    //     Comming Soon. 
    //     <a
    //       href="https://x.com/metta_powercoin"
    //       target="_blank" rel="noopener noreferrer"
    //       className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
    //     >
    //       <div className="flex items-center">
    //         <FaTwitter className="mr-2 text-gray-500" />
    //         <span>Follow Us On Twitter To Get Latest Update</span>
    //       </div>
    //       <FiExternalLink />
    //     </a>
    //   </div>
    // );

    // Toggle the sub-content visibility
    const toggleHow = () => {
      setHowOpen(!isHowOpen);
    };

    const getTokenSymbol = (tokenAddress) => tokenSymbols[tokenAddress] || 'UNKNOWN';

    // Fetch nextGameId
    // const { data: totalGameIdData, isLoading: isLoadingNextGameId } = useReadContract({
    //     ...unigameContractConfig,
    //     functionName: 'totalGameId',    
    // });
    
    // Fetch game info for selected game
    // const { data: nextGameData, isLoading: isLoadingGameInfo, error: gameInfoError } = useReadContract({
    //   ...unigameContractConfig,
    //   functionName: 'getGame',
    //   args: [nextGameId],     
    // });

    // Read contract data using wagmi's useContractRead
    // const { data: nextGamePrizeData, isLoading: isLoadingPrizeTiers, error: prizeTiersError} = useReadContract({
    //   ...unigameContractConfig,
    //   functionName: 'getPrizeTiers',
    //   args: [nextGameId],   
    //   enabled: nextGameId > 0,   
    // });

    // Read contract data using wagmi's useContractRead
    // const { data: currentGamePrizeData, isLoading: isLoadingCurrentGamePrize, error: currentGamePrizeError} = useReadContract({
    //   ...unigameContractConfig,
    //   functionName: 'getPrizeTiers',
    //   args: [nextGameId-1],
    //   enabled: nextGameId > 0,      
    // });

    // Fetch game info for selected game
    // const { data: currentGameData, isLoading: isLoadingCurrentGameData, error: currentGameDataError } = useReadContract({
    //   ...unigameContractConfig,
    //   functionName: 'getGame',
    //   args: [nextGameId-1],
    //   enabled: nextGameId > 0,
    // });

    // useEffect(() => {
    //   if (totalGameIdData) {
    //     console.log("totalGameIdData 111111:", totalGameIdData);
    //     setNextGameId(Number(totalGameIdData)); // Set nextGameId when data is fetched          
    //   }
    // }, [totalGameIdData]);
  
    // useEffect(() => {
    //   if (nextGamePrizeData) {
    //     console.log("nextGamePrizeData: ", nextGamePrizeData);
    //     const [tiers, prizes] = nextGamePrizeData;
    //     // Prepare prize data in the format needed for the table
    //     const formattedPrizeData = tiers.map((tier, index) => {
    //       // const prize = prizes[index];
    //       return {
    //         amount: prizes[index].amount,
    //         tokenAddress: prizes[index].tokenAddress
    //       };
    //     });
          
    //     console.log("formattedNextGamePrizeData: ", formattedPrizeData);
    //     setNextGamePrize(formattedPrizeData);
    //   }
      
    // }, [nextGamePrizeData]);

    // useEffect(() => {   
    //   console.log("nextGameData: ", nextGameData);   
    //   if (nextGameData) {
    //     const [id, numbers, gameTime, gameCategory, maxUnisaveIDForEntry, prizeTokenAddress, isPerformed] = nextGameData;
    //     setNextGame({ id, numbers, gameTime , gameCategory, maxUnisaveIDForEntry, prizeTokenAddress, isPerformed });              
    //   }
    // }, [nextGameData]);

    // useEffect(() => {
    //   if (currentGamePrizeData) {
    //     const [tiers, prizes] = currentGamePrizeData;
    //     // Prepare prize data in the format needed for the table
    //     const formattedPrizeData = tiers.map((tier, index) => {
    //       const prize = prizes[index];
    //       return {
    //         tier,
    //         currencies: prize.currencies
    //       };
    //     });
    //     setCurrentGamePrize(formattedPrizeData);
    //     console.log("currentGamePrizeData 11111: ",currentGamePrizeData);
    //   }
    // }, [currentGamePrizeData]);

    // useEffect(() => {
    //   if (currentGameData) {
    //     const [id, wonCommonNumbers, wonUniNumber, gameTime, claimedTokenIds] = currentGameData;
    //     setCurrentGame({ id, wonCommonNumbers, wonUniNumber, gameTime, claimedTokenIds });
    //   }
    // }, [currentGameData]);
  
    // if (isLoadingPrizeTiers || isLoadingGameInfo || isLoadingNextGameId) return <div>Loading...</div>;
    // if (prizeTiersError) return <div>Error: {prizeTiersError.message}</div>;
    // if (gameInfoError) return <div>Error: {gameInfoError.message}</div>;

    const { data: allGameInfoData, isLoading: isLoadingNextGameId } = useReadContract({
      ...unigameContractConfig,
      functionName: 'getAllGamesInfo',    
    });


    useEffect(() => {   
      console.log("allGameInfoData: ", allGameInfoData);
      if (allGameInfoData) {
        // const [id, numbers, gameTime, gameCategory, maxUnisaveIDForEntry, prizeTokenAddress, isPerformed] = nextGameData;
        // setNextGame({ id, numbers, gameTime , gameCategory, maxUnisaveIDForEntry, prizeTokenAddress, isPerformed });              
      }
    }, [allGameInfoData]);

    
    
    const today = new Date();
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    console.log(nextWeek);
        
    const handleSaveToWin = () => {
      navigate("/mint");
    };

    const handleMintTicket = () => {
      navigate("/ticket");
    }

      // Handle moving to the previous NFT
  const handlePreviousGame = () => {
    if (currentGameIndex > 0) {
      setCurrentGameIndex(currentGameIndex - 1);
    }
  };

  // Handle moving to the next NFT
  const handleNextGame = () => {
    if (currentGameIndex < allGameInfoData.length - 1) {
      setCurrentGameIndex(currentGameIndex + 1);
    }
  };

  function getKeyIndex(key) {
    if (key === "match5PlusRedNumberPrize") {
      return 0;
    } else if (key === "match5Prize") {
      return 1;
    } else if (key === "match4PlusRedNumberPrize") {
      return 2;
    } else if (key === "match4Prize") {
      return 3;
    } else if (key === "match3PlusRedNumberPrize") {
      return 4;
    } else if (key === "match3Prize") {
      return 5;
    } else if (key === "match2PlusRedNumberPrize") {
      return 6;
    } else if (key === "match1PlusRedNumberPrize") {
      return 7;
    } else if (key === "matchRedNumberPrize") {
      return 8;
    }      
  }

  if(!allGameInfoData || allGameInfoData === undefined || allGameInfoData.length == 0) {
    return (
      <div className='pt-16 text-center text-xl'>No game found.</div>
    );
  }
    

    return (      
      <div className="flex flex-col pt-16 h-min-screen justify-center items-center mb-20 md:mb-0">
        <div className='flex flex-col lg:flex-row justify-center items-center gap-1 md:gap-3'>
          <div className="text-lg md:text-xl lg:text-3xl text-center flex gap-1 md:gap-3 flex-row font-bold text-oldRed">
            <div className='text-OldBlue'>Save Together.</div>
            <div className='text-OldBlue'>Have Fun.</div>
            <div className='text-OldBlue'>Win Prizes.</div>
          </div> 
          <button className="text-white text-base md:text-lg h-full bg-[#cd0039] hover:bg-red-500 text-center lg:w-80 p-2 rounded-lg "
            onClick={handleMintTicket}>
            Save To Earn Free Lotto Ticket
          </button>     
        </div>     
        <div className="flex items-center mt-3">          
          { allGameInfoData && (
            <div className="w-full text-white grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 md:gap-10">
              <div className="flex flex-col md:aspect-square gap-3 md:gap-0 items-center justify-between p-3 md:p-7 text-center bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg">
                
                <div className='flex flex-row w-full gap-3' >  
                  <button
                    className={` bg-OldRed text-lg font-bold text-center text-white p-2 rounded-lg shadow-lg ${
                      currentGameIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handlePreviousGame}
                    disabled={currentGameIndex === 0}
                  >
                    &#8592;
                  </button>
                                    
                  <h2 className="text-xl font-bold bg-white justify-center flex items-center text-OldBlue rounded-lg p-1 md:p-2 w-full">
                    Lotto Game: {allGameInfoData[currentGameIndex]?.id.toString()}/{allGameInfoData?.length}
                  </h2>
                  
                  <button
                    className={`bg-OldRed text-lg font-bold text-white p-2 rounded-lg shadow-lg ${
                      currentGameIndex === allGameInfoData.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handleNextGame}
                    disabled={currentGameIndex === allGameInfoData.length - 1}
                  >
                    &#8594;
                  </button>
                </div>                        

                <div>
                  <p className="text-base sm:text-lg ">Draw Time</p>
                  <div className="text-base sm:text-lg font-bold">
                    {new Date(Number(allGameInfoData[currentGameIndex].drawTime) * 1000).toLocaleString()}
                    {/* {new Date(Number("1730487864") * 1000).toLocaleString()} */}
                  </div>
                </div>
    
                <div className="">                  
                  {/* <CountdownTimer targetDate={new Date(Number(1730487864) * 1000).toISOString()} /> */}                  
                  {allGameInfoData[currentGameIndex].isPerformed? <NumberDisplay numbers={allGameInfoData[currentGameIndex].resultNumbers}></NumberDisplay> :
                    <CountdownTimer targetDate={new Date(Number(allGameInfoData[currentGameIndex].drawTime) * 1000).toISOString()} /> 
                  }
                </div>

                <div className="">
                  <p className="text-base sm:text-lg">Prize Pool</p>
                  {/* <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg p-0 md:p-1">{nextGame.gameCategory ? nextGame.gameCategory.toString() : "-"}</p> */}
                  <p className="text-base sm:text-lg font-bold bg-white text-OldBlue rounded-lg p-2">300,000 Blast Gold</p>
                </div>  
                
                <div className="">
                  <p className="text-base sm:text-lg">Entry Ticket ID Limit</p>
                  <p className="text-base sm:text-lg font-bold bg-white text-OldBlue rounded-lg p-0 p-2">{allGameInfoData[currentGameIndex].maxTicketIDForEntry.toString()}</p>
                </div>                
                                              
              </div>                           
                
              <div className="flex flex-col md:aspect-square items-center justify-between p-3 md:p-7 text-center hidden md:block bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold bg-white text-OldBlue rounded-lg p-2 w-full">
                  Prize Chart
                </h2>
                <table className="w-full text-lg text-center ">
                  <thead>
                    <tr className="border-b text-center text-lg p-2">
                      <th className="p-2">Match</th>
                      <th className="p-2">Prize</th>
                      {/* <th className="p-2">Power Drop 10,000X</th> */}
                    </tr>
                  </thead>
                  <tbody className="text-white text-lg text-sm">     
                    {/* {allGameInfoData?.prizeInfo?.map((prize, index) => ( */}
                    {Object.entries(allGameInfoData[currentGameIndex].prizeInfo).map(([key, value]) => (
                      <tr className="text-lg border-b text-center hover:bg-blue-700">
                        <td className="">
                          <PrizeDisplay prizeTier={getKeyIndex(key)} bgWhiteNumber={"bg-white"}/>                        
                        </td>                             
                        <td className="p-2 text-right">
                          <span className='font-bold'>{Number(value).toLocaleString()} </span> <span className='text-base'>Blast Gold</span>
                        </td>
                      </tr> 
                    ))}                                             
                  </tbody>
                </table>                                        
              </div>
              
              {/*Mobile Display*/}
              <div className="block md:hidden w-full space-y-1">
                <p className='text-xl text-OldBlue text-center font-bold'>Prize Chart</p>
                {Object.entries(allGameInfoData[currentGameIndex].prizeInfo).map(([key, value]) => (
                  <div className="border rounded-lg p-4 bg-gradient-to-b from-[#0487E2] to-blue-900 text-white">
                    <div className='flex flex-row items-center gap-3'>
                      <p>Match:</p>
                      <PrizeDisplay prizeTier={getKeyIndex(key)} bgWhiteNumber={"bg-white"}/>
                    </div>
                    <p>Prize: {Number(value).toLocaleString()} Blast Gold</p>                      
                  </div>                      
                ))}                                          
              </div>
            
            </div>           
          )}
        </div>            
      </div>
    );            
  };
  
  export default GamesPage;