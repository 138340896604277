import React, { useState, useRef, useEffect } from 'react';
import { FaInfoCircle, FaTelegram } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

const MoreButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Ref to track the dropdown menu
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/"); // Navigate to the Mint Unisave page
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {    
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

    // Close the dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
            }
        };

        // Add event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        }, []);

  return (
    <div className="relative inline-block justify-center items-center flex text-left z-50" ref={menuRef}>
      {/* More button */}
      <button
        onClick={handleHomeClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}        
        className="inline-flex justify-center items-center text-gray-500  hover:text-black focus:outline-none"
      >
        <svg width="150" viewBox="0 0 600 110" fill="none" xmlns="http://www.w3.org/2000/svg">                      
          <text x="50%" y="50%" fontSize="92" fontWeight="bold" fill='#cd0039' dominantBaseline="middle" textAnchor="middle">
            <tspan fill="#cd0039">Save</tspan><tspan fill="#003b79">together</tspan>
            {/* Powercoin */}
          </text>
        </svg>
        <svg
          className={`ml-1 mb-1 h-4 w-4 transform transition-transform ${
            isOpen ? 'rotate-0' : 'rotate-180'
          }`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707A1 1 0 014.293 8.293l5-5A1 1 0 0110 3z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute left-0 top-5 w-48 bg-gray-100 border border-gray-200 rounded-lg shadow-lg">
          <div className="py-1">   
            {/* <a
              href="#"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex items-center">                
                <span>Ecosystem</span>
              </div>
              <FiExternalLink />
            </a> */}

            <a
              href="https://savetogether.gitbook.io/savetogether"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex items-center">
                <FaInfoCircle className="mr-2 text-purple-500" />
                <span>Docs</span>
              </div>
              <FiExternalLink />
            </a>
            <a
              href="https://t.me/+EWAgnaxbKABjNWJl"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex items-center">
                <FaTelegram className="mr-2 text-gray-500" />
                <span>Telegram</span>
              </div>
              <FiExternalLink />
            </a>
            <a
              href="https://x.com/metta_2085"
              target="_blank" rel="noopener noreferrer"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              <div className="flex items-center">
                <FaTwitter className="mr-2 text-gray-500" />
                <span>Twitter</span>
              </div>
              <FiExternalLink />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MoreButton;
