import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAccount,
  useReadContract,
  useReadContracts,
  useInfiniteReadContracts,
  useWriteContract,
} from "wagmi";

import { savingContractConfig } from '../contract.js';
import { useQuery, gql } from '@apollo/client';

const ITEMS_PER_PAGE = 10;

const GET_UNISAVE_SAVING_ACCOUNTS = gql`
  query UnisaveSavingAccounts($id: ID!) {
    saver(id: $id) {
      id
      address
      unisaveSavingAccounts{
        id
        tokenId
        numbers
        mintTime
        inviterTokenId
        inviteeTokenIds
      }
    }
  }
`;

const ListUnisaveSavingsPage = (({ isReloadSavingAccount, onReloadComplete, onRedeem, onCreateSavingAccount }) => {
  const navigate = useNavigate();
  // Get the connected wallet address using wagmi
  const { address:connectedAddress, isConnected } = useAccount();

  // Query the NFTs owned by the connected address
  const { loading, error, data, refetch:refetchSavingAccounts } = useQuery(GET_UNISAVE_SAVING_ACCOUNTS, {
    variables: { id: connectedAddress?.toLowerCase() },
    skip: !isConnected, // Skip query if not connected
  });

  console.log("data:", data);
  

  useEffect(() => {
    if (isReloadSavingAccount) {
      console.log('New Saving Created. Reloading data...');
      refetchSavingAccounts();
      onReloadComplete();
    }
  }, [isReloadSavingAccount]);

  
    
  const {
    isPending: isWithdrawPending,
    isSuccess: isWithdrawSuccess,
    writeContract: withdrawSaving,
  } = useWriteContract();

  useEffect(() => {
    if (isWithdrawSuccess && onRedeem) {
      onRedeem({ success: true, message: "Withdrawal successful" });
      refetchSavingAccounts();
    }
  }, [isWithdrawSuccess, onRedeem]);

  if (!isConnected) return <p>Please connect your wallet.</p>;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  function handleCreateSavingAccount() {
    onCreateSavingAccount();
  }

  console.log("data?.saver?.unisaveSavingAccounts:", data?.saver?.unisaveSavingAccounts.length);

  if (data?.saver?.unisaveSavingAccounts?.length == 0 || data?.saver?.unisaveSavingAccounts == undefined ) return (
    <div className="flex flex-col gap-5">
      <p className="text-lg">You have no refundable Powercoin NFT.</p>
      <button
        className="text-white bg-[#cd0039] p-2 hover:bg-red-500 text-center rounded-lg w-full"
        onClick={handleCreateSavingAccount}   
      >
        Mint Refundable Powercoin NFT
      </button>
    </div>
  );

  // const [tokenIds, setTokenIds] = useState([]); 

  // const {
  //   data: balanceOf,
  //   isLoading: isBalanceLoading,
  //   refetch: refetchBalance,
  // } = useReadContract({
  //   ...savingContractConfig,
  //   functionName: "balanceOf",
  //   args: [address],
  // });

  // const nftBalance = balanceOf ? Number(balanceOf) : 0;

  // console.log("nftBalance:", nftBalance);

  // const {
  //   data: unisavesData,
  //   isLoading: isNftsLoading,
  //   fetchNextPage,
  //   hasNextPage,
  //   refetch,
  // } = useInfiniteReadContracts({
  //   cacheKey: "nftAttributes",
  //   contracts(pageParam) {
  //     const startIndex = pageParam * ITEMS_PER_PAGE;
  //     const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, nftBalance);

  //     const contracts = [];
  //     for (let i = startIndex; i < endIndex; i++) {
  //       contracts.push({
  //         ...savingContractConfig,
  //         functionName: "tokenOfOwnerByIndex",
  //         args: [address, i],
  //       });
  //     }

  //     return contracts;
  //   },
  //   query: {
  //     initialPageParam: 0,
  //     getNextPageParam: (_lastPage, allPages, lastPageParam) => {
  //       const totalFetched = allPages.flatMap((page) => page).length;
  //       return totalFetched < nftBalance ? lastPageParam + 1 : undefined;
  //     },
  //   },
  //   enabled: !!balanceOf && nftBalance > 0,
  // });

  // useEffect(() => {
  //   if (unisavesData) {
  //     const newTokenIds = unisavesData.pages
  //       .flatMap((page) => page.map((result) => result.result))
  //       .filter((tokenId) => tokenId !== null);
  //     setTokenIds((prevTokenIds) => [
  //       ...new Set([...prevTokenIds, ...newTokenIds]),
  //     ]);
  //   }
  // }, [unisavesData]);

  // useEffect(() => {
  //   if (
  //     !isWithdrawSuccess &&
  //     nftBalance > 0 &&
  //     !isNftsLoading &&
  //     (!unisavesData || unisavesData.pages[0].length === 0)
  //   ) {
  //     refetchBalance();
  //     refetch();
  //   }
  // }, [nftBalance, isNftsLoading, unisavesData, isWithdrawSuccess]);

  // const { data: metadataResults, isLoading: isMetadataLoading } = useReadContracts({
  //   contracts: tokenIds.map((tokenId) => ({
  //     ...savingContractConfig,
  //     functionName: "tokenURI",
  //     args: [tokenId],
  //   })),
  //   enabled: tokenIds.length > 0,
  // });

  // const [nftMetadata, setNftMetadata] = useState({});

  

  const handleRedeem = (tokenId) => {
    console.log(`Withdraw clicked for NFT ID: ${tokenId.toString()}`);
    withdrawSaving({
      ...savingContractConfig,
      functionName: "redeem",
      args: [tokenId],
    });
  };

  const handleWinning= (unisaveTokenId, unisaveNumbers) => {        
    navigate(`/Rewards?unisaveTokenId=${unisaveTokenId}&unisaveNumbers=${unisaveNumbers}`); // Pass value as a query param
  }

  const handleSell= (tokenId) => {    
    window.open('https://www.blur.io', '_blank');
  }

  function formatDate(timestamp) {
    // Convert the timestamp to milliseconds if it's in seconds
    const date = new Date(timestamp * 1000);
  
    // Extract the year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
  
    // Return in 'Year.Month.Day' format
    return `${year}.${month}.${day}`;
  }

  return (
    <div className="flex items-center w-full">
      {data && <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
        {data?.saver?.unisaveSavingAccounts?.map((unisave) => {                    
          return (
            <li key={unisave.id} className="border flex flex-col rounded">                                
                  <div className="flex rounded">
                    <svg width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                      <rect width="100%" height="100%" fill="#003b79"/>
                      <rect x="300" y="145" width="405" height="130" rx="20" stroke="#fff" strokeWidth="3" fill="#fff"/>
                      <text x="500" y="210" fontSize="90" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79">
                        <tspan>Uni</tspan><tspan fill="#cd0039">save</tspan></text>
                      <rect x="50" y="420" width="900" height="160" rx="20" stroke="#fff" strokeWidth="3" fill="none"/>
                      <circle cx="150" cy="500" r="60" fill="#fff"/>
                      <text x="150" y="500" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79">{unisave.numbers[0].toString().padStart(2, '0')}</text>
                      <circle cx="290" cy="500" r="60" fill="#fff"/>
                      <text x="290" y="500" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79">{unisave.numbers[1].toString().padStart(2, '0')}</text>
                      <circle cx="430" cy="500" r="60" fill="#fff"/>
                      <text x="430" y="500" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79">{unisave.numbers[2].toString().padStart(2, '0')}</text>
                      <circle cx="570" cy="500" r="60" fill="#fff"/>
                      <text x="570" y="500" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79">{unisave.numbers[3].toString().padStart(2, '0')}</text>
                      <circle cx="710" cy="500" r="60" fill="#fff"/>
                      <text x="710" y="500" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79">{unisave.numbers[4].toString().padStart(2, '0')}</text>
                      <circle cx="850" cy="500" r="60" fill="#cd0039"/>
                      <text x="850" y="500" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#fff">{unisave.numbers[5].toString().padStart(2, '0')}</text>
                      {/* <text x="50" y="630" fontSize="35" dy=".35em" fill="#fff">Invited: {unisave.inviteeTokenIds? unisave.inviteeTokenIds.length : 0}</text> */}
                      {/* <text x="805" y="630" fontSize="35" textAnchor="middle" dy=".35em" fill="#fff">Minted: {formatDate(unisave.mintTime)}</text>  */}
                      <rect x="300" y="725" width="405" height="130" rx="20" stroke="#fff" strokeWidth="3" fill="#fff"/>
                      <text x="500" y="790" fontSize="70" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#003b79"><tspan>100 </tspan><tspan fill="#cd0039">USDB</tspan></text>
                      {/* <text x="500" y="950" fontSize="35" textAnchor="middle" fill="white">unisave.io</text> */}
                    </svg>          
                  </div>
                  <div className="flex justify-between items-center gap-3">                    
                    {/* <button className="flex-1 text-black bg-white p-2 hover:bg-gray-100 text-center 
                      rounded-lg w-full hover:text-zinc-500" onClick={() => handleWinning(unisave.tokenId, unisave.numbers)}>
                      Reward
                    </button>
                    <button className="flex-1 text-black bg-white p-2 hover:bg-gray-100 text-center 
                      rounded-lg w-full hover:text-zinc-500" onClick={() => handleSell(unisave.tokenId)}>
                      Sell
                    </button> */}
                    <button className="flex-1 text-black bg-white p-2 hover:bg-gray-100 text-center 
                      rounded-lg w-full hover:text-zinc-500" onClick={() => handleRedeem(unisave.tokenId)}>
                      Redeem
                    </button>
                  </div>
                
              {/* ) : (
                <div>Loading metadata...</div>
              )} */}
            </li>
          );
        })}
      </ul>}

      {/* {hasNextPage && (
        <button className="mt-4 p-2 bg-unisaveBg hover:bg-unisaveHover text-white rounded" onClick={() => refetch()}>
          Load More
        </button>
      )} */}
    </div>
  );
});

export default ListUnisaveSavingsPage;
