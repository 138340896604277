// src/queries.js

import { gql } from '@apollo/client';

export const GET_USER_CHECKING_ACC_DATA = gql`
  query GetUserCheckingAccData($id: ID!) {
    user(id: $id) {
      id
      balance
      whitelisted      
    }
  }
`;


export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      balance
      isWhitelisted
    }
  }
`;

export const GET_DEPOSITS = gql`
  query GetDeposits {
    deposits {
      id
      user {
        id
      }
      amount
      timestamp
    }
  }
`;

export const GET_WITHDRAWALS = gql`
  query GetWithdrawals {
    withdrawals {
      id
      user {
        id
      }
      amount
      timestamp
    }
  }
`;

export const GET_WHITELIST_UPDATES = gql`
  query GetWhitelistUpdates {
    whitelistUpdates {
      id
      user {
        id
      }
      status
      timestamp
    }
  }
`;

export const GET_WHITELIST_TOGGLES = gql`
  query GetWhitelistToggleds {
    whitelistToggleds(orderBy: timestamp, orderDirection: desc, first: 1) {
      enabled
      timestamp
    }
  }
`;