import React from 'react';

// Component to display the prize
const PrizeDisplay = ({ prizeTier, bgWhiteNumber }) => {
  const prizeArray = [5,5,4,4,3,3,2,1,0];
  
  return (
    <div className="flex flex-row items-center">
      {/* White Ball */}
      {[...Array(prizeArray[prizeTier])].map((_, index) => (
        <div key={index} className={`flex items-center justify-center h-6 w-6 md:h-8 md:w-8 rounded-full ${bgWhiteNumber} mr-2`}/>                  
      ))}

      {/* Red Ball */}
      
      {((prizeTier == 0) ||  (prizeTier == 2) ||  (prizeTier == 4) ||  (prizeTier == 6) ||  (prizeTier == 7) ||  (prizeTier == 8))? (    
        <div className="flex flex-row items-center">
          {prizeTier < 8 ? <div className="text-2xl font-bold text-black mr-2">+</div> : "" }
          <div className="flex items-center justify-center h-6 w-6 md:h-8 md:w-8 rounded-full bg-red-600 text-white font-bold"/>
        </div>        
      ): (<></>)
      }
    </div>
  );
};

export default PrizeDisplay;
