import UNISAVE_NFT_CONTRACT_ABI from "./abi/UnisaveNFT.json";
import USDB_ABI from "./abi/USDBToken.json";
import UNIGAME_ABI from "./abi/Unigame.json";
import UNISAVE_TOKEN_ABI from "./abi/UnisaveToken.json";
import UNISAVE_CHECKING_ACCOUNT_CONTRACT_ABI from "./abi/UnisaveCheckingAccounts.json";
import PRIZE_MANAGER_ABI from "./abi/PrizeManager.json";
import JACKPOT_ABI from "./abi/Jackpot.json";
import PowerDrop10000X from "./abi/PowerDrop10000X.json";


export const savingContractConfig = {
  address: "0x84887C24759555Cf6D80fbd478080b59D2543C53",
  abi: UNISAVE_NFT_CONTRACT_ABI,
};

export const usdbContractConfig = {
  address: "0x4200000000000000000000000000000000000022",
  abi: USDB_ABI,
};

export const unigameContractConfig = {
  address: "0xC6D02d7E6a74579B8ca8b4C03036F4ccEa4ff2A7",
  abi: UNIGAME_ABI,
};

export const prizeManagerContractConfig = {
  address: "0x77d24F7e4D83E73C529e2163F6Ae56a00A52E4Ab",
  abi: PRIZE_MANAGER_ABI,
};

export const unisaveTokenContractConfig = {
  address: "0xdb78C54EbC788F1848e57F0aCfAb1923E645484b",
  abi: UNISAVE_TOKEN_ABI,
};

export const checkingContractConfig = {
  address: "0x5393175362D03e6f440E5c79d82428c7c2827920",
  abi: UNISAVE_CHECKING_ACCOUNT_CONTRACT_ABI,
};

export const jackpotContractConfig = {
  address: "0xFe5c5d912c43797AbF93674BA32227EB3A21173d",
  abi: JACKPOT_ABI,
};

export const powerDrop10000XContractConfig = {
  address: "0x9E925AC19E07422D359D5f0Be69Fa4eaA2d3ddcC",
  abi: PowerDrop10000X,
};

export const tokenSymbols = {
  '0xC1741C04A366DcCbC8854eA368807b0a2AF770c9': 'USDB',
  '0xdb78C54EbC788F1848e57F0aCfAb1923E645484b': 'SAVE',
};

export const UNISAVE_PRICE = 100;
