import React, { useState, useEffect } from 'react';
import { useAccount, useReadContract } from 'wagmi';
import JackpotMenu from "../components/JackpotMenu.jsx";
import { ethers } from 'ethers';
import { FiExternalLink } from 'react-icons/fi';
import { prizeManagerContractConfig, tokenSymbols } from "../contract.js";
import { formatUnits } from 'viem';
import CountdownTimer from "../components/CountdownTimer.jsx";


const JackpotsPage = () => {
  const [activeTab, setActiveTab] = useState('Assets');
  const { address, isConnected } = useAccount();
  const [currentJackpotId, setCurrentJackpotId] = useState(null);
  const [erc20ContributionsForLatestJackpot, setERC20ContributionsForLatestJackpot] = useState(null);
  const [erc721ContributionsForLatestJackpot, setERC721ContributionsForLatestJackpot] = useState(null);

  const getTokenSymbol = (tokenAddress) => tokenSymbols[tokenAddress] || 'UNKNOWN';

  const { data: currentJackpotIdData, isLoading: isCurrentJackpotIdLoading } =
  useReadContract({
    ...prizeManagerContractConfig,
    functionName: "jackpotTokenIdCounter", 
  });

  useEffect(() => {        
    if (currentJackpotIdData) {
      console.log("currentJackpotIdData:", currentJackpotIdData);
      setCurrentJackpotId(currentJackpotIdData);
    }
  }, [currentJackpotIdData]);

  
  const { data: currentJackpotERC20ContributionsData, isLoading: isLoadingJackpot, error: jackpotDataError } = useReadContract({
    ...prizeManagerContractConfig,
    functionName: 'getAllERC20ContributionsForJackpot',    
    args: [currentJackpotId],
    enabled: currentJackpotId > 0,
  });

  const { data: currentJackpotERC721ContributionsData } = useReadContract({
    ...prizeManagerContractConfig,
    functionName: 'getAllERC721ContributionsForJackpot',    
    args: [currentJackpotId],
    enabled: currentJackpotId > 0,
  });

  useEffect(() => {    
    if (currentJackpotERC20ContributionsData) {
      console.log("currentJackpotERC20ContributionsData:", currentJackpotERC20ContributionsData);
      setERC20ContributionsForLatestJackpot(currentJackpotERC20ContributionsData);
    }
  }, [currentJackpotERC20ContributionsData]);

  useEffect(() => {    
    if (currentJackpotERC721ContributionsData) {
      console.log("currentJackpotERC721ContributionsData:", currentJackpotERC721ContributionsData);
      setERC721ContributionsForLatestJackpot(currentJackpotERC721ContributionsData);
    }
  }, [currentJackpotERC721ContributionsData]);

  const handleClaimToken = async () => {
    console.log("claim Token");
    
  };

  return (
    <div className="min-h-screen mb-20 flex flex-col gap-5">
      <JackpotMenu _activeTab={"currentJackpot"} />
      <div className="flex gap-3 items-center grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 ">        
        <div className="flex flex-col gap-3 text-white h-full flex-1 items-center justify-between p-5 text-center bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg">
          <h2 className="text-lg sm:text-xl font-bold bg-white text-red-600 rounded-lg p-2 w-full">
            Jackpot ID: {currentJackpotId?.toLocaleString()}
          </h2>
          {/* <div>
            <p className="text-base sm:text-lg ">Draw Time</p>
            <div className="text-base sm:text-lg font-bold">
              {/* {new Date(Number(nextGame.gameTime) * 1000).toLocaleString()} */}
              {/*{new Date(Number("1728487864") * 1000).toLocaleString()}
            </div>
          </div> */}

          {/* <div className="">            
            <CountdownTimer targetDate={new Date(Number(1728487864) * 1000).toISOString()} />
          </div> */}

          <div className="">
            <p className="text-base sm:text-lg">Total Asset Sponsors</p>
            <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg">{erc20ContributionsForLatestJackpot?.length.toLocaleString()}</p>
          </div>
          <div className="">
            <p className="text-base sm:text-lg">Total Collectible Sponsors</p>
            <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg">{erc721ContributionsForLatestJackpot?.length.toLocaleString()}</p>
          </div>
          <div className="">
            <p className="text-base sm:text-lg">Estimated Jackpot Value</p>
            <p className="text-base sm:text-lg font-bold bg-white text-red-600 rounded-lg p-2">$10,000,000 USDB</p>
          </div>
          
          <button className="text-white bg-[#cd0039] hover:bg-red-500 text-center p-3 rounded-lg w-full">
            Save To Earn
          </button>
        </div>         

        {/* <div className="w-full border-2 h-[32.5rem] flex-fit rounded-lg bg-gradient-to-b from-[#0487E2] to-blue-900"> */}
        <div className="flex flex-col gap-3 text-white flex-1 items-center justify-between p-5 text-center bg-gradient-to-b from-[#0487E2] to-blue-900 rounded-lg shadow-lg">          
          {/* Tabs: Collectibles and Assets */}          
          <div className="text-lg sm:text-xl font-bold bg-white text-red-600 rounded-lg p-2 w-full">
            <button
              className={`w-1/2 ${activeTab === 'Assets' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('Assets')}
            >
              Asset Sponsors
            </button>

            <button
              className={`w-1/2 ${activeTab === 'Collectibles' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('Collectibles')}
            >
              Collectible Sponsors
            </button>          
          </div>

          {/* Tab Content */}
          <div className="w-full">
            {activeTab === 'Collectibles' ? (
              <div className='flex flex-col h-[26rem] overflow-y-auto bg-white rounded-lg'>
                {/* Collectibles Content */}
                <p>Collectibles Content</p> 
              </div>
            ) : (              
              <div className='flex flex-col h-[26rem] overflow-y-auto  '>
                {/* Assets Content */}
                <ul className="">
                    {erc20ContributionsForLatestJackpot?.map((contribution) => (                    
                      <li
                        key={contribution.contributionId}                        
                        className={`p-2 flex flex-col-2 justify-between text-white ${
                          contribution.contributionId !== 1 ? "border-t" : ""              
                        } hover:text-black`}
                      >
                        <div className="flex flex-col justify-between gap-2">
                          <a
                            href="#"
                            className="flex gap-1 text-center items-center text-xl hover:text-black"
                          >
                            <div className="flex text-center items-center">                
                              <span>{contribution.sponsorName}</span>                        
                            </div>
                            <FiExternalLink />
                          </a>
                          <div className='flex flex-row items-center gap-1'>                                                                    
                            <p>{Number(formatUnits(BigInt(contribution.amount), 18)).toLocaleString()}</p>   
                            <p>{getTokenSymbol(contribution.tokenAddress)} </p>                                       
                          </div>                                                       
                        </div>
                        <button className='bg-red-600 text-white p-2 rounded' onClick={handleClaimToken}>
                              Claim
                          </button>                 
                      </li>                  
                    ))}
                </ul>
              </div>
            
            )}
          </div>  
        </div>              
      </div>
    </div>
  );
};

export default JackpotsPage;
