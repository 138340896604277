import React, { useState, useEffect } from "react";
import { useAccount, useReadContract, useWatchContractEvent , useWriteContract, useInfiniteReadContracts, useWaitForTransactionReceipt } from "wagmi";
import { powerDrop10000XContractConfig, usdbContractConfig } from "../contract.js";
import { useQuery, gql } from '@apollo/client';
import TopMenu from "../components/TopMenu.jsx";
import { useNavigate } from "react-router-dom";

const GET_NFTS_BY_OWNER = gql`
query getOwnedTokens($id: ID!) {
  account(id: $id) {    
    nftBalance
    ownedTokens
  }
}
`;

const PortfolioPage = () => {
  const navigate = useNavigate();
  const { address: connectedAddress, isConnected } = useAccount();
  const [tokenIds, setTokenIds] = useState([]);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const ITEMS_PER_PAGE = 5;
  

  const {
    data: redeemHash,
    isPending: isRedeemPending,
    isSuccess: isRedeemSuccess,
    writeContract: redeem,
  } = useWriteContract();

  // Query the NFTs owned by the connected address
  const { loading, error, data:nftOwnedByAddress, refetch:refetchNFT } = useQuery(GET_NFTS_BY_OWNER, {
    variables: { id: connectedAddress?.toLowerCase() },
    skip: !isConnected, // Skip query if not connected
    pollInterval: 3000,
  });

  console.log("nftOwnedByAddress.account.ownedTokens:", nftOwnedByAddress?.account?.ownedTokens);

  const { data, isError: isRedeemReceiptError, isSuccess: isRedeemReceptSuccess} = useWaitForTransactionReceipt({
    hash: redeemHash,
  });

  // Watch for Transfer events from the contract and refetch the owned NFTs
  useWatchContractEvent({
    ...powerDrop10000XContractConfig,
    eventName: "Transfer",
    // args: {
    //   to: connectedAddress,
    //   from: connectedAddress
    // },
    onLogs(logs) {
      console.log('New logs!', logs)
      refetchNFT();
    },
  });

  // const { data: balanceOf, refetch: fetchBalance } = useReadContract({
  //   ...powerDrop10000XContractConfig,
  //   functionName: "balanceOf",
  //   args: [connectedAddress],   
  // });

  // useEffect(() => {    
  //   if(balanceOf) {
  //     const tokenBal = Number(balanceOf);
  //     setTokenBalance(tokenBal); 
  //     setTokenIds([]);
  //     fetchTokenIds();           
  //   }
  // }, [balanceOf]);

  // useEffect(() => {  
  //   // fetchTokenIds();   
  //   if(tokenBalance) {
  //     console.log("tokenBalance:", tokenBalance);
  //     if(tokenBalance > 0) {    
  //       console.log("fetchTokenIds:");            
  //     }
  //   }
  // }, [tokenBalance]);

  // const {
  //   data: nftData,
  //   isLoading: isNftsLoading,
  //   fetchNextPage,
  //   hasNextPage,
  //   refetch:fetchTokenIds,
  // } = useInfiniteReadContracts({
  //   cacheKey: ["nftAttributes", tokenBalance],
  //   contracts(pageParam) {
  //     const startIndex = pageParam * ITEMS_PER_PAGE;
  //     const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, tokenBalance);

  //     const contracts = [];
  //     for (let i = startIndex; i < endIndex; i++) {
  //       contracts.push({
  //         ...powerDrop10000XContractConfig,
  //         functionName: "tokenOfOwnerByIndex",
  //         args: [connectedAddress, i],
  //       });
  //     }

  //     return contracts;
  //   },
  //   query: {
  //     initialPageParam: 0,
  //     getNextPageParam: (_lastPage, allPages, lastPageParam) => {
  //       const totalFetched = allPages.flatMap((page) => page).length;
  //       return totalFetched < tokenBalance ? lastPageParam + 1 : undefined;
  //     },
  //   },
  //   enabled: !!tokenBalance && tokenBalance > 0,
  // });

  // useEffect(() => {
  //   console.log("nftData:", nftData);
  //   if (nftData) {
  //     const newTokenIds = nftData.pages
  //       .flatMap((page) => page.map((result) => result.result))
  //       .filter((tokenId) => tokenId !== null);
  //     setTokenIds((prevTokenIds) => [...new Set([...prevTokenIds, ...newTokenIds])]);
  //     setIsLoading(false);
  //   }
  // }, [nftData]);

  const handleRedeem = (tokenId) => {
    console.log(`Redeem clicked for tokenId ${tokenId}`);
    redeem({
      ...powerDrop10000XContractConfig,
      functionName: "redeem",
      args: [tokenId],
    });
  };

  useEffect(() => {
    console.log("nftOwnedByAddress.", nftOwnedByAddress) 
    setIsShowMessage(false);
    if (nftOwnedByAddress) {
      setTokenIds(nftOwnedByAddress?.account?.ownedTokens);    
      setTokenBalance(nftOwnedByAddress?.account?.nftBalance);
    }
  }, [nftOwnedByAddress]);

  useEffect(() => {
    console.log("isRedeemReceptSuccess.", isRedeemReceptSuccess);
    setIsShowMessage(true);
    if (isRedeemReceptSuccess) {
      // setTokenIds((prevTokenIds) => prevTokenIds.filter((id) => id !== redeemHash.args[0].toString()));
      refetchNFT();
      setMessage("Redeem Success. Updating Display...");
    }
    if (isRedeemSuccess) {
      setMessage("Redeem Processing...");
    }
    if (isRedeemReceiptError) {
      setMessage("Redeem Error.");
    }
  }, [isRedeemReceiptError, isRedeemReceptSuccess, isRedeemSuccess]);

  const handleGoToMint = () => {
    navigate("/mint");
  }

  const renderSvgImages = () => {
    return tokenIds?.map((tokenId) => (
      <div className="grid grid-cols-1 mt-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-center gap-3">
        <div key={tokenId} className="flex flex-col items-center aspect-square text-lg gap-1 rounded-lg p-3 border-2">        
          <svg width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="100%" fill="#003b79" />
            <polygon transform="translate(130, 200) scale(0.28)" points="100,20 130,75 200,75 145,120 160,180 100,150 40,180 55,120 0,75 70,75" fill="#fff" />
            <polygon transform="translate(790, 200) scale(0.28)" points="100,20 130,75 200,75 145,120 160,180 100,150 40,180 55,120 0,75 70,75" fill="#fff" />
            <polygon points="50,100 100,50 900,50 950,100 950,900 900,950 100,950 50,900" fill="none" stroke="white" strokeWidth="10" />
            <text x="500" y="250" fontSize="60" fontWeight="bold" textAnchor="middle" fill="#FFF">POWERCOIN</text>
            <rect x="90" y="410" width="820" height="180" rx="20" stroke="#fff" strokeWidth="7" fill="#cd0039" />
            <text x="500" y="530" fontSize="81" fontWeight="bold" textAnchor="middle" fill="WHITE">Free To Play Lottery</text>
            <text x="500" y="750" fontSize="60" fontWeight="bold" textAnchor="middle" dy=".35em" fill="#fff">10,000 POWER</text>
            <text x="500" y="975" fontSize="38" textAnchor="middle" dy=".35em" fill="#fff">powercoin.cash</text>
          </svg>
          
          {/* Redeem Button below SVG */}
          <button
            onClick={() => handleRedeem(tokenId)}
            className="mt-2 px-4 py-2 bg-[#cd0039] text-white rounded w-full hover:bg-red-500"
          >
            Redeem
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div className="flex flex-col mt-3">      
      {isConnected ? (
        <div className="flex flex-col justify-center pt-3 md:pt-0 items-center">
          <div className="w-full rounded-lg text-left text-white p-3 bg-gradient-to-b from-[#0487E2] to-blue-900">
            <p className="font-medium">Total Portfolio</p>
            <h1 className="text-2xl font-bold">{(tokenBalance? tokenBalance * 100: 0).toString()} USDB</h1>
          </div>
          
          {isShowMessage  && <p className="text-green-600">{message.toString()}</p>}        
          
          {loading ? (
            <div>Loading...</div>
          ) : (            
            <div className="flex items-center gap-3">
              {tokenBalance > 0? renderSvgImages(): 
              (<button className="mt-2 px-4 py-2 bg-[#cd0039] text-white rounded w-full hover:bg-red-500"
                onClick={() => handleGoToMint()}
              >Mint Powercoin Refundable and Airdropable NFT</button>)}
            </div>
          )}
        </div>
      ) : (
        <div className="mt-20">Please connect your wallet</div>
      )}
      </div>
  );
};

export default PortfolioPage;
