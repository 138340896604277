import React, { useEffect, useState } from "react";

const CountdownTimer = ({ targetDate }) => { 
  // console.log("targetDate:", targetDate); 
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTimeUnit = (unit) => typeof unit === 'number'? String(unit).padStart(2, '0'): "-";
  

  return (
    <div className="flex justify-center space-x-6 text-unisaveBg">
      <div className="flex flex-col items-center">
        <div className="bg-white text-OldBlue pl-7 pr-7 rounded-md">
          <span className="text-2xl font-bold">{formatTimeUnit(timeLeft.hours)}</span>
        </div>
        <p className="text-white mt-1 text-sm">HOURS</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-white text-OldBlue pl-7 pr-7 rounded-md">
          <span className="text-2xl font-bold">{formatTimeUnit(timeLeft.minutes)}</span>
        </div>
        <p className="text-white text-sm mt-1">MINUTES</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-white text-OldBlue pl-7 pr-7 rounded-md">
          <span className="text-2xl font-bold">{formatTimeUnit(timeLeft.seconds)}</span>
        </div>
        <p className="text-white mt-1 text-sm">SECONDS</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
