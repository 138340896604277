import React from 'react';

// Component to display each number with colored border
const NumberCircle = ({ number, index }) => {
    const circleClasses = index === 5 
    ? 'bg-red-500 text-white' // Sixth circle with red background and white text
    : 'bg-white text-black'; // Other circles

    return (        
        <div className={`flex items-center justify-center font-bold text-lg h-12 w-12 mx-0.5 md:text-2xl md:h-14 md:w-14 md:mx-1 rounded-full ${circleClasses} shadow-md`}>                                             
          {number === null ? '' : number}          
        </div>     
    );
};

// Component to display the array comparison
const NumberDisplay = ({ numbers }) => {
  const firstFive = numbers.slice(0, 5);
  const sortedFirstFive = firstFive.sort((a, b) => (a > b ? 1 : -1));
  const reorderedResultNumbers = sortedFirstFive.concat(numbers.slice(5));  

  return (
    <div className="flex justify-center items-center">
      {reorderedResultNumbers.map((num, index) => (
        <NumberCircle         
          key={index}
          number={Number(num)}    
          index={index}      
        />        
      ))}
    </div>
  );
};

export default NumberDisplay;
